import { Component, OnInit } from '@angular/core';
import { first, max } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '@app/_services';
import { Utility } from '@app/_helpers';
import { Enums } from '@app/_models';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ElementSchemaRegistry } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({ templateUrl: 'project-detail.html' })
export class ProjectDetailComponent implements OnInit {
  loading = false;
  projectDetialForm: FormGroup;
  submitted = false;
  projectDetail: any;
  arrProjectImages: any;
  arrNonProjectUsers: any;
  arrAllRoles: any;
  projectStats: any;
  timeStats: any;
  arrAllProjectUsers: any;
  arrAllProjectActiveUsers: any;
  currentUser: any;
  currentRole: any;
  ButtonText: String;
  enums: any;
  showLabelButton: Boolean;
  showApproverButton: Boolean;
  enabledLabelButton: Boolean;
  enabledApproverButton: Boolean;
  lightboxImageFile: any;
  lightboxWidth: any;
  lightboxHeight: any;
  lightboxId: number;
  showLoadMore: boolean = false;
  current_userid: Number;
  currentRoleId: Number;
  currentRoleName: String;
  currentUserEmail: String;
  currentLabelerUserStats: any;
  currentSkippedUserStats: any;
  currentApproverUserStats: any;
  currentDisapproverUserStats: any;
  currentRejectedUserStats: any;
  currentTotalReviewStats: any;
  maxcountlabeler:any;
  maxcountreviewer:any;
  allusers:any;
  allimages:any;
  maxcountinglabeler:any;
  maxcountingreviewer;
  finalcountlabeler:any;
  finalcountreviewer:any;
  validate_labeled_images: number = 0;
  Validate_approved_images: number = 0;
  max_count_labeler:any;
  max_count_reviewer:any;
  initialMaxCountOne:any;
  initialMaxCountTwo:any;
  maxcountone:any;
  maxcounttwo:any;

  currentUserActiveTimeStats: any;
  currentUserTotalActiveTimeStats: any = 0;
  currentUserAverageActiveTimeStats: any = 0;

  constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private utility: Utility,
    private router: Router,
    private route: ActivatedRoute
  ) {}
a
  ngOnInit() {
    this.projectDetialForm = this.formBuilder.group({
      email: ['', Validators.required],
      userRole: ['', Validators.required],
    });
    this.enums = Enums;
    eval('ReadyEvent()');
    this.current_userid = JSON.parse(localStorage.getItem('user')).user.id;
    this.currentRoleId = JSON.parse(localStorage.getItem('user')).user.user_type;
    this.currentUserEmail = JSON.parse(localStorage.getItem('user')).user.email;
    this.currentRoleName = this.GetCurrentUserRole();
    
    this.GetAllProjects();
    this.GetProjectImages();
    this.GetNonAddedUsersOfProject();
    this.GetAllRoles();
    this.GetActiveTimeStats();
  }


   /**
   * This function will allow admin to set max count according to project details for
   * labeler and reviewer
   */

  setmaxcount(max_input_count_labeler:number,max_input_count_approver:number){

    let labelermaxcount = 0;
    let reviewermaxcount = 0;
    let labelerinitialcount:any;
    let approverinitialcount:any;
    
    labelerinitialcount = 0;
    approverinitialcount = 0;


    this.projectDetail;


    // getting total numbers of images we have in current project 
    this.allimages = this.projectDetail.total_images;

    //getting total number of users we have in our current project
    this.allusers = this.arrAllProjectUsers.length;

    // Initializing Max Count by dividing number of total images to number of total users.

    // Initial labeler max count 
    labelerinitialcount = this.arrAllProjectUsers.filter((initiallabelermaxcount:any)=>{
      if(initiallabelermaxcount.role_details.id == this.enums.Role_Labeler_Id){
      labelerinitialcount++;
      return labelerinitialcount;
      }
    }); 
    // initial approver max conunt
    approverinitialcount = this.arrAllProjectUsers.filter((initialapprovermacount:any)=>{
      if(initialapprovermacount.role_details.id == this.enums.Role_Approver_Id ){
        approverinitialcount++;
      return approverinitialcount;
      }
    });

    // getting initial max counts in variables
    this.initialMaxCountOne = Math.round (this.allimages / labelerinitialcount.length);
    console.log(this.initialMaxCountOne);
    // if(this.initialMaxCountOne % 2 == 1){
    //   this.initialMaxCountOne++;
    // }
    // console.log(this.initialMaxCountOne);

    this.initialMaxCountTwo = Math.round (this.allimages / approverinitialcount.length);
    console.log(this.initialMaxCountTwo);
    // if(this.initialMaxCountTwo % 2 == 1){
    //   this.initialMaxCountTwo++;
    // }
    // console.log(this.initialMaxCountTwo);

    //getting total numbers of labelers in the current project
    this.maxcountlabeler = this.arrAllProjectUsers.filter((labelercount:any)=>{
      if(labelercount.role_details.id == this.enums.Role_Labeler_Id){
      labelermaxcount++;
      return this.maxcountlabeler;
      }
    }); 
    this.maxcountinglabeler = labelermaxcount;

    // getting total number of approvers in the current project.
    this.maxcountreviewer = this.arrAllProjectUsers.filter((reviewercount:any)=>{
      if(reviewercount.role_details.id == this.enums.Role_Approver_Id){
      reviewermaxcount++;
      return this.maxcountreviewer;
      }
    }); 
    this.maxcountingreviewer = reviewermaxcount;

    // getting total max count for both reviewer and labeler
    this.finalcountlabeler =  Math.round( this.allimages / this.maxcountinglabeler);
    this.finalcountreviewer = Math.round (this.allimages / this.maxcountingreviewer);
  

    // Validating if the max count entered by user is greater than the total number of images.
    if(max_input_count_labeler > this.allimages || max_input_count_approver > this.allimages)
    {
      this.utility.DisplayMessage(
        Enums.Message_Labeler_Greater,
        Enums.Message_Type_Error
      );
      return false;
    }

     // Validating if the max count entered by user is Less than the total number of images.
    if(max_input_count_labeler < this.initialMaxCountOne || max_input_count_approver < this.initialMaxCountTwo )
    {
      this.utility.DisplayMessage(
        Enums.Message_Labeler_Lesser,
        Enums.Message_Type_Error
      );
      return false;
    } 

    // geeting total number of approved and labeled images we have in current project. 
    
    this.validate_labeled_images = 0;
    this.Validate_approved_images = 0;
    let labelingvalidation =  this.projectStats.results.filter((obj, i) => {
      if ( obj.status == 'labeled')
      {
       this.validate_labeled_images++;
      }
      else if(obj.status == 'approved')
      {
        this.Validate_approved_images++;
      }

    });

    // getting values in variables for services
    this.max_count_labeler = max_input_count_labeler;
    this.max_count_reviewer = max_input_count_approver;

    // calling services
    this.GetMaxCount( this.route.snapshot.params['id'],this.max_count_labeler, this.max_count_reviewer);
  }

  // Service for getting max count values for labeler and reviewer.
  GetMaxCount(projectId: Number, labelercount: Number,  approvercount: Number){
    this.projectService
      .SetMaxCountToProject(projectId, labelercount, approvercount)
      .subscribe(
        (response: any) => {

          if (response != null) {
            // this.setmaxcount = response; 
            console.log(response);         
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
      this.projectService.getProjectDetails(projectId).subscribe(
        (response:any) =>{
          if(response !=null){
            this.maxcountone = response.max_count_labeler;
            this.maxcounttwo = response.max_count_reviewer;  
          }
        }
      )
  }

  /**
   * General function -
   * Triggering a on Change event request from DOM
   * @param element 
   */
  triggerChange(element) {
    let changeEvent = new Event('change');
    element.dispatchEvent(changeEvent);
  }
  
  /**
   * Add the selected user to currently opened project
   * @returns 
   */
  AddProjectUser() {
    
    if (
      this.projectDetialForm.status.toLowerCase() ==
      Enums.Form_Status_Valid.toLowerCase()
    ) {

      this.GetProjectUsers();

      let arrAlreadyAddedUser = this.arrAllProjectUsers.filter((user:any)=>{
        if(user.user_details.id == this.projectDetialForm.controls.email.value.toLowerCase())
        return user;
      });
      
        /**
         * If selected user is not added to project, then add it
         */
        if(arrAlreadyAddedUser.length == 0 )
        {
          this.projectService
            .AddUserToProject(
              this.route.snapshot.params['id'],
              this.projectDetialForm.controls.email.value,
              this.projectDetialForm.controls.userRole.value
            )
            .subscribe(
              (response: any) => {
                if (response != null) {
                  this.GetProjectUsers();
                  this.submitted = true;
                }
              },
              (error) => {
                this.utility.DisplayMessage(
                  Enums.Message_Failure,
                  Enums.Message_Type_Error
                );
              }
            );
        }
        /**
         * If selected user is already added to project, but it is disabled (disassociated)
         * from project then allow it to add again
         */
        else if( arrAlreadyAddedUser.length > 0 && !arrAlreadyAddedUser[0].is_active ){
          this.DeleteUserFromProject(
            arrAlreadyAddedUser[0].user_details.id,
            arrAlreadyAddedUser[0].user_details.user_type,
            arrAlreadyAddedUser[0].project, 
            arrAlreadyAddedUser[0].user_details.email,
            arrAlreadyAddedUser[0].id,
            true,
            false );

            /**
             * Select default email value after deletion
             */
            let defaultEmailVal = (<HTMLInputElement>document.querySelector('#email'));
            defaultEmailVal.value = "";
            this.triggerChange(defaultEmailVal);
            this.projectDetialForm.valid;
            this.projectDetialForm.controls.email.valid;
            this.submitted = false;
        }
        /**
         * If selected user is already added to project, then show exception that it is 
         * already added
         */
        else{
          this.submitted=false;
          this.utility.DisplayMessage(
            Enums.Message_Projectuser_Exist,
            Enums.Message_Type_Error
          );
          return false;
        }
        // this.submitted = true;
    }
  }

  GetAllProjects() {
    let _this = this;

    /**
     * After API requestes, the main loading spinner keeps on
     * loading until unless the stats are not completely loaded.
     */
     let loding_spinner = document.querySelector('.spinner-overlay');
     loding_spinner.classList.add('show-spinner');

    this.projectService
      .getProjectDetails(this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.projectDetail = response;
            this.GetProjectUsers();
            setTimeout(function () {
              //_this.GetProjectStats();
              _this.GetCurrentProjectStats();
              /**
               * After API requestes finished loading, the main loading spinner will
               * be removed
               */
              loding_spinner.classList.remove('show-spinner');
            }, 500);

          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  GetActiveTimeStats() {
    this.projectService
      .getActiveTimeStats( this.route.snapshot.params['id'], this.current_userid )
      .subscribe(
        (response: any) => {

          this.currentUserTotalActiveTimeStats = 0;
          this.currentUserAverageActiveTimeStats = 0;

          if (response != null) {
            this.timeStats = response;
            if( this.timeStats.count > 0 ){
              let results = this.timeStats.results;
              this.currentUserActiveTimeStats = 0;
              results.forEach(( element:any, i:any ) => {
                this.currentUserActiveTimeStats +=  +element.active_time
                
              });

              this.currentUserTotalActiveTimeStats = this.secondsToHms( this.currentUserActiveTimeStats );
              this.currentUserAverageActiveTimeStats = this.secondsToHms( this.currentUserActiveTimeStats / this.timeStats.count );
              
            }
            
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  GetProjectStats() {
    this.projectService
      .getProjectStats(this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.projectStats = response;

            if (this.currentRole == Enums.Role_Labeler) {
              this.currentLabelerUserStats =
                this.projectStats.labeler_data.filter((obj) => {
                  if (this.currentUser.user_details.email == obj.email)
                    return obj;
                });

              this.currentSkippedUserStats =
                this.projectStats.skipped_data.filter((obj) => {
                  if (this.currentUser.user_details.email == obj.email)
                    return obj;
                });

              this.currentDisapproverUserStats =
                this.projectStats.disapproved_data.filter((obj) => {
                  //if ( this.currentLabelerUserStats.length > 0 ){
                    if( this.currentUser.user_details.email == obj.email )
                      return obj;
                  //}
                    
                });

                console.log(this.currentLabelerUserStats);

            } else if (this.currentRole == Enums.Role_Approver) {
              this.currentApproverUserStats =
                this.projectStats.approver_data.filter((obj) => {
                  if (this.currentUser.user_details.email == obj.email)
                    return obj;
                });

              this.currentRejectedUserStats =
                this.projectStats.rejected_data.filter((obj) => {
                  if (this.currentUser.user_details.email == obj.email)
                    return obj;
                });
            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  GetCurrentProjectStats() {

    let currentUserRole = this.GetCurrentUserRole();
    let currentUserId: any;
    if ( currentUserRole == Enums.Role_Admin )
      currentUserId = ""
    else
      currentUserId = this.current_userid
    
    this.projectService
      .getCurrentProjectStats( this.route.snapshot.params['id'], currentUserId )
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.projectStats = response;
            console.log(this.currentRole);
            console.log(Enums.Role_Labeler);
            if (this.currentRole == Enums.Role_Labeler) {
                let total_labeled_count = 0;
                this.projectStats.results.filter((obj, i) => {
                  if ( obj.status == Enums.Image_Status_Labeled )
                    total_labeled_count++;
                });

                this.currentLabelerUserStats = total_labeled_count;

                let total_skipped_count = 0;
                this.projectStats.results.filter((obj) => {
                  if ( obj.status == Enums.Image_Status_Skipped )
                    total_skipped_count++;
                });

                this.currentSkippedUserStats = total_skipped_count;

                let total_disapproved_count = 0;
                this.projectStats.results.filter((obj) => {
                  if ( obj.status == Enums.Image_Status_Disapproved )
                    total_disapproved_count++;
                });
                this.currentDisapproverUserStats = total_disapproved_count;

                let total_approved_count = 0;
                this.projectStats.results.filter((obj) => {
                  if ( obj.status == Enums.Image_Status_Approved )
                    total_approved_count++;
                });

                this.currentApproverUserStats = total_approved_count;

            } else if (this.currentRole == Enums.Role_Approver) {
                let total_approved_count = 0;

                this.projectStats.results.filter((obj) => {
                  if ( obj.status == Enums.Image_Status_Approved )
                    total_approved_count++;
                });

                this.currentApproverUserStats = total_approved_count;

                let total_disapproved_count = 0;
                this.projectStats.results.filter((obj) => {
                  if ( obj.status == Enums.Image_Status_Disapproved )
                    total_disapproved_count++;
                });
                this.currentDisapproverUserStats = total_disapproved_count;

                this.currentTotalReviewStats = this.projectStats.count;

            } else if (this.currentRole == Enums.Role_Admin) {
                
                let total_approved_count = 0;
                this.projectStats.results.filter((obj) => {
                  if ( obj.status == Enums.Image_Status_Approved )
                    total_approved_count++;
                });

                
                this.currentApproverUserStats = total_approved_count;

                let total_disapproved_count = 0;
                this.projectStats.results.filter((obj) => {
                  if ( obj.status == Enums.Image_Status_Disapproved )
                    total_disapproved_count++;
                });
                this.currentDisapproverUserStats = total_disapproved_count;

                let total_labeled_count = 0;
                this.projectStats.results.filter((obj, i) => {
                    if ( obj.status == Enums.Image_Status_Labeled )
                      total_labeled_count++;
                  });

                this.currentLabelerUserStats = total_labeled_count;

            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  
  getLabelerAndReviwerCountForAdmin(userId: Number, status: String ){
    let total_count = 0;
    // console.log(this.projectStats);
    // console.log(this.projectStats.results);
    if( this.projectStats !== undefined && Object.keys(this.projectStats).length > 0 &&  this.projectStats.results.length > 0 ){
      this.projectStats.results.filter((obj, i) => {
        if ( ( obj.perform_by == userId || obj.perform_for == userId ) 
              && obj.status == status )
          total_count++;
      });
    }
    return total_count;
  }

  RedirectToProjectAnnotation(projectId: Number, action: String) {
    this.router.navigate(['/annotation/' + projectId + '/' + action]);
  }
  DetachDataset(dataset_id: Number) {
    this.projectService
      .detachDataset(dataset_id, this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          this.GetAllProjects();
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  /**
   * This function will update the user active flag to false, which will make that user
   * unaccessible of project labeling/reviewing.
   * @param userId 
   * @param userRole 
   * @param projectId 
   * @param status 
   * @param userEmail 
   */
  DeleteUserFromProject(
    userId: Number,  
    userRole: Number,
    projectId: Number,
    userEmail: String,
    objectID: Number,
    status: Boolean, 
    showAlert: Boolean
    ){
    
    let isConfirm = ( showAlert ) ? confirm("Are you sure to delete "+userEmail+" ?") : true;
    if (isConfirm) {
      /**
       * We are not deleting the user but actually setting the Status 
       * to false
       */
      this.projectService
      .DeleteUserFromProject(
        projectId, 
        userId,
        userRole,
        status,
        objectID
        )
      .subscribe(
        (response: any) => {
          if (response != null) {
            /**
             * This will remove the exact delete user from the user list array
             */
            this.arrAllProjectUsers.forEach((element,index)=>{
              if( element.id == objectID ) this.arrAllProjectActiveUsers.splice(index,1);
            });

            /**
             * Refresh users list
             */
            this.GetProjectUsers();
            /**
             * After deleting, refresh users and release it from the 
             * current user list
             */
            this.GetNonAddedUsersOfProject();

            /**
             * Select default email value after deletion
             */
            let defaultEmailVal = (<HTMLInputElement>document.querySelector('#email'));
            defaultEmailVal.value = "";
            this.triggerChange(defaultEmailVal);
            this.submitted = false;
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
    }
  }
  GetProjectImages() {
    this.projectService
      .getProjectImages(Enums.Image_Type_All, this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.arrProjectImages = response.results;
            console.log(this.arrProjectImages);
            if (response.count > Enums.Dataset_Images_Limit) {
              this.showLoadMore = true;
            } else {
              this.showLoadMore = false;
            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  // lightbox
  openModal(id) {
    document.getElementById('lightboxModal').classList.remove('lightbox-hide');
    const imageId = id;
    this.arrProjectImages.map((val) => {
      if (val.id === id) {
        this.lightboxId = val.id;
        this.lightboxImageFile = val.image_file;
        this.lightboxWidth = val.width;
        this.lightboxHeight = val.height;
      }
    });
  }
  closeModal() {
    document.getElementById('lightboxModal').classList.add('lightbox-hide');
  }
  //  end lightbox

  // load more button
  LoadMoreRecord(dataset_id: Number, offset: Number, limit: Number) {
    this.projectService
      .getDatasetImages(dataset_id, limit, Enums.Dataset_Images_Limit)
      .subscribe(
        (response: any) => {
          if (response.count > 0) {
            if (this.arrProjectImages) {
              this.arrProjectImages = [
                ...this.arrProjectImages,
                ...response.results,
              ];
              if (this.arrProjectImages.length === response.count) {
                this.showLoadMore = false;
              }
            } else {
              this.arrProjectImages = response.results;
            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  LoadMoreRecords() {
    let dataset_id = this.arrProjectImages[0].data_set;
    if (this.arrProjectImages) {
      this.LoadMoreRecord(
        dataset_id,
        Enums.Dataset_Images_Limit,
        this.arrProjectImages.length
      );
    }
  }

  GetNonAddedUsersOfProject() {
    this.projectService
      .getAllNonAddedUserOfProject(this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.arrNonProjectUsers = response;
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  GetAllRoles() {
    this.projectService.getAllRoles().subscribe(
      (response: any) => {
        if (response != null) {
          this.arrAllRoles = response.results;
        }
      },
      (error) => {
        this.utility.DisplayMessage(
          Enums.Message_Failure,
          Enums.Message_Type_Error
        );
      }
    );
  }

  GetProjectUsers() {
    
    this.projectService
      .getProjectUsers(this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.arrAllProjectUsers = response.results;
            this.arrAllProjectActiveUsers = this.arrAllProjectUsers;
            this.SetCurrentUserPermissions();
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  
  SetCurrentUserPermissions() {
    let objCurrentUser = this.arrAllProjectUsers.filter((user) => {
      if ( user.user_details.email == this.currentUserEmail ) return user;
    })[0];

    this.currentUser = objCurrentUser;
    this.currentRole = this.currentRoleName;

    if (this.currentRole == Enums.Role_Approver) {
      this.showApproverButton = true;
      this.showLabelButton = false;
    } else if (this.currentRole == Enums.Role_Labeler) {
      this.showApproverButton = false;
      this.showLabelButton = true;
    } else if (this.currentRole == Enums.Role_Admin) {
      this.showApproverButton = true;
      this.showLabelButton = true;
    }

    // if user is deleted from project then disable Label & Approve button
    this.arrAllProjectUsers.filter((user) => {
      if ( user.user_details.id == this.current_userid && !user.is_active ) {
        this.showLabelButton = false;
        this.showApproverButton = false;
      }
    })

    this.enabledLabelButton = false;
    this.enabledApproverButton = false;
    
    if (
      this.projectDetail.total_labeled_images == 0 &&
      this.currentRole == Enums.Role_Admin
    ) {
      this.enabledLabelButton = true;
      this.enabledApproverButton = false;
    }

    // if one image is label and label images is less than total images and current user role is Admin, unable start approving button
    if (
      this.projectDetail.total_labeled_images > 0 &&
      this.projectDetail.total_labeled_images <
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Admin
    ) {
      this.enabledLabelButton = true;
      this.enabledApproverButton = true;
    }

    // if all images are labeled but not annotated all images and current user role is Admin, disable labeling button and unable approving button
    if (
      this.projectDetail.total_labeled_images ==
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Admin
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = true;
    }

    // if label images and total annotated images are equal but there are more images to label and current user role is Admin, disable start approving button as there is not label image to approve
    if (
      this.projectDetail.total_labeled_images ==
        this.projectDetail.total_annotated_images &&
      this.projectDetail.total_labeled_images <
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Admin
    ) {
      this.enabledLabelButton = true;
      this.enabledApproverButton = false;
    }

    // if all labeled images are approved and current user role is Admin, disable labeling button and approving button
    if (
      this.projectDetail.total_annotated_images ==
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Admin
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = false;
    }

    // if no image is label and current user role is labeler, unable start labeling button
    if (
      (this.projectDetail.total_labeled_images == 0 ||
        this.projectDetail.total_labeled_images <
          this.projectDetail.total_images) &&
      this.currentRole == Enums.Role_Labeler
    ) {
      this.enabledLabelButton = true;
      this.enabledApproverButton = false;
    }
    
    // if all images are labeled and current user role is labeler, disable start labeling button
    if (
      this.projectDetail.total_labeled_images ==
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Labeler
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = false;
    }

    // if all images are labeled and current user role is labeler and disapprove images are less than the total images, enable start labeling button
    if ( this.projectDetail.total_disapproved_images > 0 &&
      ( this.projectDetail.total_labeled_images - this.projectDetail.total_disapproved_images ) <=
      this.projectDetail.total_images &&
    this.currentRole == Enums.Role_Labeler
  ) {
    this.enabledLabelButton = true;
    this.enabledApproverButton = false;
    }

    // if no image is label and current user role is approval, disable start approving button
    if (
      this.projectDetail.total_labeled_images == 0 &&
      this.currentRole == Enums.Role_Approver
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = false;
    }

    // if an image is label but is less than total images and current user role is approval, unable start approving button
    if (
      this.projectDetail.total_labeled_images > 0 &&
      this.projectDetail.total_labeled_images <
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Approver
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = true;
    }

    // if there are labeled images but there are less than total images and label images and approved images are equal and current user role is Approval, disable start approving button
    if (
      this.projectDetail.total_labeled_images <
        this.projectDetail.total_images &&
      this.projectDetail.total_labeled_images ==
        this.projectDetail.total_annotated_images &&
      this.currentRole == Enums.Role_Approver
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = false;
    }

    // if all the images are labeled but not approved all the labeled images and current user role is Approval, unable Start Approving button
    if (
      this.projectDetail.total_labeled_images ==
        this.projectDetail.total_images &&
      this.projectDetail.total_annotated_images <
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Approver
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = true;
    }

    // if all the images are labeled and approved and current user role is approval, disable start approving button
    if (
      this.projectDetail.total_labeled_images ==
        this.projectDetail.total_images &&
      this.projectDetail.total_annotated_images ==
        this.projectDetail.total_images &&
      this.currentRole == Enums.Role_Approver
    ) {
      this.enabledLabelButton = false;
      this.enabledApproverButton = false;
    }

    // // if all the images are labelled and current user role is labeler
    // if (
    //   this.projectDetail.total_labeled_images ==
    //     this.projectDetail.total_images &&
    //   (this.currentRole == Enums.Role_Labeler ||
    //     this.currentRole == Enums.Role_Admin)
    // ) {
    //   this.enabledLabelButton = false;
    //   this.enabledApproverButton = false;
    // }
    // // if atleast 1 image is not labelled and current user role is labeler
    // else if (
    //   this.projectDetail.total_labeled_images <
    //     this.projectDetail.total_images &&
    //   (this.currentRole == Enums.Role_Labeler ||
    //     this.currentRole == Enums.Role_Admin)
    // ) {
    //   this.enabledLabelButton = true;
    //   this.enabledApproverButton = false;
    // }

    // // if all the images are labelled and current user role is approver
    // if (
    //   this.projectDetail.total_labeled_images ==
    //     this.projectDetail.total_images &&
    //   (this.currentRole == Enums.Role_Approver ||
    //     this.currentRole == Enums.Role_Admin)
    // ) {
    //   this.enabledLabelButton = false;
    //   this.enabledApproverButton = true;
    // }
    // // if all the images are approved and current user role is approver
    // else if (
    //   this.projectDetail.total_annotated_images ==
    //     this.projectDetail.total_images &&
    //   (this.currentRole == Enums.Role_Approver ||
    //     this.currentRole == Enums.Role_Admin)
    // ) {
    //   this.enabledLabelButton = false;
    //   this.enabledApproverButton = false;
    // }
    // // if atleast 1 image is not approved and current user role is approver
    // else if (
    //   this.projectDetail.total_annotated_images <
    //     this.projectDetail.total_images &&
    //   (this.currentRole == Enums.Role_Approver ||
    //     this.currentRole == Enums.Role_Admin)
    // ) {
    //   this.enabledLabelButton = false;
    //   this.enabledApproverButton = true;
    // }
    // // if atleast 1 images is labelled and some there are some images that are not approved yet
    // else if (
    //   this.projectDetail.total_labeled_images > 0 &&
    //   this.projectDetail.total_annotated_images <
    //     this.projectDetail.total_images &&
    //   (this.currentRole == Enums.Role_Approver ||
    //     this.currentRole == Enums.Role_Admin)
    // ) {
    //   this.enabledLabelButton = false;
    //   this.enabledApproverButton = true;
    // } else {
    //   this.enabledLabelButton = false;
    //   this.enabledApproverButton = false;
    // }

    // // if current user is approver and there is not labelled image
    // if (
    //   (this.currentRole == Enums.Role_Approver ||
    //     this.currentRole == Enums.Role_Admin) &&
    //   this.projectDetail.total_labeled_images == 0
    // ) {
    //   this.enabledLabelButton = false;
    //   this.enabledApproverButton = false;
    // }
    // // if current user is labeler and there are some images that needs to be labelled
    // if (
    //   (this.currentRole == Enums.Role_Labeler ||
    //     this.currentRole == Enums.Role_Admin) &&
    //   this.projectDetail.total_labeled_images < this.projectDetail.total_images
    // ) {
    //   this.enabledLabelButton = true;
    //   this.enabledApproverButton = false;
    // }

    // this.enabledLabelButton = true;
    // this.enabledApproverButton = true;
  }
 

  IsCurrentUserIsLabelerOrApprover() {
    let IsUserLaborApp = false;
    if (
      this.currentRole == Enums.Role_Labeler ||
      this.currentRole == Enums.Role_Approver
    ) {
      IsUserLaborApp = true;
    }
    return IsUserLaborApp;
  }
  isAdmin(){
    return ( this.currentRole === "Admin" ) ? true: false ;
  }
  SelectUser() {
    let selectedUser = this.arrNonProjectUsers.filter((user) => {
      if (user.id == this.projectDetialForm.controls.email.value) return user;
    })[0];
    this.projectDetialForm.controls.userRole.setValue(selectedUser.user_type);
  }
  ExportProject() {
    this.projectService
      .exportProject(this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            if (response.length > 0) {
              let arrFinal: any = [];

              response.forEach((element: any) => {
                let imgId = element.id;
                let strAnnotations = element.annotation;
                let arrClassifiers = [];
                let arrRegions = [];
                let x_mapping_factor = element.x_mapping_factor;
                let y_mapping_factor = element.y_mapping_factor;

                x_mapping_factor == null ? 1 : x_mapping_factor;
                y_mapping_factor == null ? 1 : y_mapping_factor;
                
                element.classifier.forEach((classif) => {
                  arrClassifiers.push({
                    name: classif.classifier,
                    type: 'radio',
                    value: classif.answer,
                  });
                });

                if (strAnnotations != '' && strAnnotations != null) {
                  let arrAnnotation = JSON.parse(strAnnotations);
                  arrAnnotation.forEach((annot) => {
                    
                    if (annot != '' && annot != null) {
                      let arrSplittedPoints = annot.split(',').map((x) => +x);
                      let arrXPpoints = [];
                      let arrYPpoints = [];

                      for (var i = 0; i < arrSplittedPoints.length; i += 2) {
                        // take every first element
                        arrXPpoints.push(
                          Number(arrSplittedPoints[i]) * x_mapping_factor
                        );
                      }
                      for (var i = 1; i < arrSplittedPoints.length; i += 2) {
                        // take every second element
                        arrYPpoints.push(
                          Number(arrSplittedPoints[i]) * y_mapping_factor
                        );
                      }
                      
                      
                      arrRegions.push({
                        name: 'polygon',
                        all_points_x: arrXPpoints,
                        all_points_y: arrYPpoints,
                      });
                    }
                  });
                }

                let objFormat = {
                  [imgId]: {
                    filename: element.image,
                    regions: [
                      {
                        shape_attributes: arrRegions,
                      },
                    ],
                    file_attributes: arrClassifiers,
                  },
                };
                arrFinal.push(objFormat);
              });

              var element = document.createElement('a');
              element.setAttribute(
                'href',
                'data:text/plain;charset=utf-8,' +
                  encodeURIComponent(JSON.stringify(arrFinal))
              );
              element.setAttribute(
                'download',
                this.utility.IDGenerator() + '.json'
              );

              element.style.display = 'none';
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
            } else {
              this.utility.DisplayMessage(
                Enums.Message_Failure,
                Enums.Message_No_Data_For_Export
              );
            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  secondsToHms(d:any) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
  }

  GetCurrentUserRole(){

    let userRoleId = JSON.parse(localStorage.getItem('user')).user.user_type;
    let currentUserRoleName: any;

    if( userRoleId == Enums.Role_Approver_Id )
      currentUserRoleName = Enums.Role_Approver
    else if ( userRoleId == Enums.Role_Labeler_Id )
      currentUserRoleName = Enums.Role_Labeler
    else  
      currentUserRoleName = Enums.Role_Admin

    return currentUserRoleName;
  } 
}
function count(arg0: boolean): any {
  throw new Error('Function not implemented.');
}

