import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { ProjectListingComponent } from './project/project-listing.component';
import { ProjectNewComponent } from './project/project-new.component';
import { DatasetListingComponent } from './dataset/dataset-listing.component';
import { DatasetNewComponent } from './dataset/dataset-new.component';
import { ProjectDetailComponent } from './project/project-detail.component';
import { AnnotationComponent } from './annotation/annotation.component';

import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { AuthGuard } from './_helpers';
import { Role } from './_models';

const routes: Routes = [
  {
    path: '',
    component: ProjectListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'project/listing',
    component: ProjectListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/new',
    component: ProjectNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/detail/:id',
    component: ProjectDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dataset/listing',
    component: DatasetListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dataset/new',
    component: DatasetNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'annotation/:id/:action',
    component: AnnotationComponent,
    canActivate: [AuthGuard],
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
