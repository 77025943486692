<div class="app-container" >
    <div class="spinner-overlay" [hidden]="!loading">
        <div class="spinner">
            <div class="spinner-dot"></div>
            <div class="spinner-dot"></div>
            <div class="spinner-dot"></div>
            <div class="spinner-dot"></div>
            <div class="spinner-dot"></div>
            <div class="spinner-dot"></div>
        </div>
    </div>
    <header class="fixed-top scroll-change" data-menu-anima="fade-in" style="height: 60px;" *ngIf="user">
        <div class="navbar navbar-default navbar-fixed-top" role="navigation">
            <div class="navbar navbar-main">
                <div class="container">
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle">
                            <i class="fa fa-bars"></i>
                        </button>
                        <a class="navbar-brand" routerLink="/">
                            <img class="logo-default" src="../assets/images/logo.png" alt="logo">
                            <img class="logo-retina" src="../assets/images/logo.png" alt="logo">
                        </a>
                    </div>
                    <div class="collapse navbar-collapse">
                        <ul class="nav navbar-nav">
                            <li class="dropdown mega-dropdown" *ngIf="isAdmin || user">
                                <a class="dropdown-toggle" data-toggle="dropdown" href="#">Project <span
                                        class="caret"></span></a>
                                <div class="mega-menu dropdown-menu multi-level row bg-menu fade-in"
                                    style="transition-duration: 0ms; animation-duration: 300ms; transition-timing-function: ease; transition-delay: 0ms;">
                                    <div class="col">
                                        <ul class="fa-ul no-icons text-s">
                                            <li><a routerLink="/project/new" *ngIf="!user || isAdmin">New </a></li>
                                            <li><a routerLink="/project/listing">Listing</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            <li class="dropdown mega-dropdown" *ngIf="isAdmin">
                                <a class="dropdown-toggle" data-toggle="dropdown" href="#">Dataset <span
                                        class="caret"></span></a>
                                <div class="mega-menu dropdown-menu multi-level row bg-menu fade-in"
                                    style="transition-duration: 0ms; animation-duration: 300ms; transition-timing-function: ease; transition-delay: 0ms;">
                                    <div class="col">
                                        <ul class="fa-ul no-icons text-s">
                                            <li><a routerLink="/dataset/new">New </a></li>
                                            <li><a routerLink="/dataset/listing">Listing </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="nav navbar-nav navbar-right">
                            <!-- <ul class="nav navbar-nav lan-menu">
                            <li class="dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"><img src="../images/en.png" alt="" />En<span class="caret"></span></a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a href="#"><img src="../images/it.png" alt="" />IT</a>
                                    </li>
                                </ul>
                            </li>
                        </ul> -->
                            <a href="#" class="dropdown-toggle header-profile-anchor" data-toggle="dropdown" role="button">
                                <span class="version-number">V 0.96</span>
                                <img src="../assets/images/users/team-2.jpg" class="header-profile-image" alt="">
                                <span class="header-profile-name">{{fullName}}</span>
                                <span class="caret mt-none"></span>
                            </a>
                            <ul class="dropdown-menu header-profile-ul">
                                <li>
                                    <a href="javascript:void(0);" (click)="logout()">Logout</a>
                                    <!-- <a href="#"><img src="../images/it.png" alt="" />IT</a> -->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
<!-- nav -->
<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="user">
    <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="/">Home</a>
        <a class="nav-item nav-link" routerLink="/admin" *ngIf="isAdmin">Admin</a>
        <a class="nav-item nav-link" (click)="logout()">Logout</a>
    </div>
</nav> -->

<!-- main app container -->

<router-outlet></router-outlet>
</div>