import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatasetService } from '@app/_services';
import { Utility } from '@app/_helpers';
import { Enums } from '@app/_models';

@Component({
  templateUrl: 'dataset-listing.html',
  encapsulation: ViewEncapsulation.None,
})
export class DatasetListingComponent implements OnInit {
  loading = false;
  datasetListingForm: FormGroup;
  arrData: any[];
  notEmptyPost = true;
  notscrolly = true;
  datasetOffset: number = Enums.Dataset_Offset;
  datasetLimit: number = Enums.Dataset_Limit;
  arrDatasetImages: any;
  currentDatasetName: String;
  showLoadMore: boolean = false;
  public Search_Query: any = '';
  lightboxImageFile: any;
  lightboxWidth: any;
  lightboxHeight: any;
  lightboxId: number;

  constructor(
    private formBuilder: FormBuilder,
    private datasetService: DatasetService,
    private utility: Utility
  ) {}

  ngOnInit() {
    this.datasetListingForm = this.formBuilder.group({
      name: [],
    });
    eval('ReadyEvent()');
    this.GetAllDatasets();
  }

  onSubmit() {}
  GetAllDatasets() {
    this.datasetService
      .getAllDataset(this.datasetOffset, this.datasetLimit)
      .subscribe(
        (response: any) => {
          if (response.count > 0) {
            this.arrData = response.results;
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  onScroll() {
    // console.log('scrolled');
    if (this.notscrolly && this.notEmptyPost) {
      this.notscrolly = false;
      this.loadNextPost();
    }
  }

  loadNextPost() {
    // return last post from the array
    const lastPost = this.arrData[this.arrData.length - 1];
    // get id of last post
    const lastPostId = lastPost.id;

    // sent this id as key value pare using formdata()
    const dataToSend = new FormData();
    dataToSend.append('id', lastPostId);
    // call http request
    this.datasetOffset = this.datasetLimit;
    this.datasetLimit += Enums.Dataset_Limit;
    this.datasetService
      .getAllDataset(this.datasetOffset, this.datasetLimit)
      .subscribe((response: any) => {
        if (response.count > 0) {
          const newDatasets = response.results;
          if (newDatasets.length === 0) {
            this.notEmptyPost = false;
          }
          // add newly fetched posts to the existing post
          this.arrData = this.arrData.concat(newDatasets);
          this.notscrolly = true;
        }
      });
  }

  GetDatasetImages(dataset_id: Number, limit: Number, datasetName: String) {
    this.datasetService
      .getDatasetImages(dataset_id, 0, Enums.Dataset_Images_Limit)
      .subscribe(
        (response: any) => {
          if (response.count > 0) {
            this.currentDatasetName = datasetName;
            this.arrDatasetImages = response.results;

            if (response.count > Enums.Dataset_Images_Limit) {
              this.showLoadMore = true;
            } else {
              this.showLoadMore = false;
            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  // lightbox
  openModal(id) {
    document.getElementById('lightboxModal').classList.remove('lightbox-hide');
    const imageId = id;
    this.arrDatasetImages.map((val) => {
      if (val.id === id) {
        this.lightboxId = val.id;
        this.lightboxImageFile = val.image_file;
        this.lightboxWidth = val.width;
        this.lightboxHeight = val.height;
      }
    });
  }
  closeModal() {
    document.getElementById('lightboxModal').classList.add('lightbox-hide');
  }
  //  end lightbox

  LoadMoreRecord(dataset_id: Number, offset: Number, limit: Number) {
    this.datasetService
      .getDatasetImages(dataset_id, limit, Enums.Dataset_Images_Limit)
      .subscribe(
        (response: any) => {
          if (response.count > 0) {
            if (this.arrDatasetImages) {
              this.arrDatasetImages = [
                ...this.arrDatasetImages,
                ...response.results,
              ];
              if (this.arrDatasetImages.length === response.count) {
                this.showLoadMore = false;
              }
            } else {
              this.arrDatasetImages = response.results;
            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  LoadMoreRecords() {
    let dataset_id = this.arrDatasetImages[0].data_set;
    if (this.arrDatasetImages) {
      this.LoadMoreRecord(
        dataset_id,
        Enums.Dataset_Images_Limit,
        this.arrDatasetImages.length
      );
    }
  }
  DeleteDataset(dataset_id: Number) {
    this.datasetService.deleteDataset(dataset_id).subscribe(
      (response: any) => {
        this.arrDatasetImages = [];
        this.currentDatasetName = '';
        this.GetAllDatasets();
      },
      (error) => console.log(`dataset of id ${dataset_id} is not deleted`)
    );
  }
}
