<div class="header-title ken-burn" data-parallax="scroll" data-position="top" data-natural-height="650" data-natural-width="1920" data-image-src="../../assets/images/bg-12.png">
    <div class="container" style="margin-top: 0px; opacity: 1;">
        <div class="title-base" style="margin-top: 97px;">
            <hr class="anima">
            <h1>Project Listing</h1>
            <p>
                Here you will see all the projects, initially you will see 5 projects.In order to load more projects you can click on pagination below the listing
            </p>
        </div>
    </div>
</div>
<div class="section-empty section-item">

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <div class="project-search-form">
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                    <input id="txtProjectName" name="txtProjectName" placeholder="Search the Project" type="text" class="form-control form-value" [(ngModel)]="Search_Query" />
                </div>
                <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <button class="btn btn-sm" type="submit"><i class="im-arrow-around"></i>Search</button>
                </div> -->
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="project-search-form">
                <button class="btn btn-sm" type="submit" (click)="RedirectToNewProject();" *ngIf="isAdmin"><i class="im-paper-plane"></i>New Project</button>
            </div>
        </div>
    </div>





</div>
<div class="section-bg-color bg-color-2">
    <div class="container content">
        <div class="row">

            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="list-items">
                    <div class="list-item" *ngFor="let data of arrData | searchFilter: Search_Query | paginate: { itemsPerPage: Page_Size, currentPage: Page_Number }">
                        <div class="row" (click)="RedirectToProjectDetail(data.id);">
                            <div class="col-md-9">
                                <h3>{{data.name}}</h3>
                                <p>{{data.description}}</p>
                            </div>
                            <div class="col-md-3">
                                <span class="text-s">{{data.status}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="list-item">
                        <div class="row">
                            <div class="col-md-9">
                                <h3>3D Rendering project</h3>
                                <p>Lorem ipsum dolor sit</p>
                            </div>
                            <div class="col-md-3">
                                <span class="text-s">Pending setup</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="row">
                            <div class="col-md-9">
                                <h3>Tax consulting</h3>
                                <p>Sunt in culpa qui officia deserunt</p>
                            </div>
                            <div class="col-md-3">
                                <span class="text-s">4 Labels</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="row">
                            <div class="col-md-9">
                                <h3>Small product adjustment</h3>
                                <p>Excepteur sint occaecat cupidatat non proident</p>
                            </div>
                            <div class="col-md-3">
                                <span class="text-s">7 Labels</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="row">
                            <div class="col-md-9">
                                <h3>House transfert</h3>
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse</p>
                            </div>
                            <div class="col-md-3">
                                <span class="text-s">Completed</span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <pagination-controls (pageChange)="Page_Number = $event" class="col-center text-center"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<!-- <div class="col-center text-center">
    <div class="list-nav mt-lg">
        <ul class="pagination-sm pagination-maso pagination" data-page-items="6" data-pagination-anima="show-scale">
            <li class="first"><a href="#"><i class="fa fa-angle-double-left"></i> <span>First</span></a></li>
            <li class="prev">
                <a href="#"> <i class="fa fa-angle-left"></i> <span>Previous</span></a>
            </li>
            <li class="page"><a href="#">1</a></li>
            <li class="page active"><a href="#">2</a></li>
            <li class="next disabled"><a href="#"><span>Next</span> <i class="fa fa-angle-right"></i></a></li>
            <li class="last disabled"><a href="#"><span>Last</span> <i class="fa fa-angle-double-right"></i></a></li>
        </ul>
    </div>
</div> -->
<i class="scroll-top scroll-top-mobile fa fa-sort-asc"></i>