import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ProjectService } from '@app/_services';
import { Utility } from '@app/_helpers';
import { Enums } from '@app/_models';
import { RouterModule, Router } from '@angular/router';
import { User, Role } from '../_models';
import { AuthenticationService } from '../_services';

@Component({ templateUrl: 'project-listing.html' })
export class ProjectListingComponent implements OnInit {
  loading = false;
  arrData: any;
  Page_Number: number = 1;
  Page_Size: number = Enums.Page_Size;
  public Search_Query: any = '';
  user: User;

  constructor(
    private projectService: ProjectService,
    private utility: Utility,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.user.subscribe((x) => (this.user = x));
  }
  get isAdmin() {
    return this.user.user.user_type === Role.Admin ? true : false;
  }
  ngOnInit() {
    eval('ReadyEvent()');
    this.GetAllProjects();
  }
  GetAllProjects() {
    this.projectService.getAllProjects(0, 100000000).subscribe(
      (response: any) => {
        if (response.count > 0) {
          this.arrData = response.results;
        }
      },
      (error) => {
        this.utility.DisplayMessage(
          Enums.Message_Failure,
          Enums.Message_Type_Error
        );
      }
    );
  }
  RedirectToNewProject() {
    this.router.navigate(['/project/new']);
  }
  RedirectToProjectDetail(projectId: Number) {
    this.router.navigate([`/project/detail/${projectId}`]);
  }
}
