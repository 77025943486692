<div
  class="header-title containerHeader"
  data-parallax="scroll"
  data-position="top"
  data-natural-width="1920"
  data-natural-height="650"
  data-image-src="../../assets/images/bg-16.jpg"
>
  <div class="container1">
    <div class="titleBase">
      <div *ngIf="projectDetail" class="titleBaselCustom">
        <h1>{{projectDetail.name}}</h1>
        <p>{{projectDetail.description}}</p>
      </div>
      <div class="titleBaseCustomButton">
        <div style="margin-top: 55px">
          <button
            class="btn-sm btn nav-justified"
            style="width: 25%"
            type="submit"
            *ngIf="isAdmin()"
            [ngClass]="isAdmin() ? 'show-btn' : ''"
            (click)="ExportProject()"
          >
            Export
          </button>
          <button
            class="btn-sm btn nav-justified"
            [disabled]="!enabledLabelButton"
            *ngIf="showLabelButton"
            [ngClass]="showLabelButton ? 'show-btn' : ''"
            style="width: 25%; margin-left: 20px"
            type="submit"
            (click)="RedirectToProjectAnnotation(projectDetail.id,enums.Role_Labeler);"
          >
            Start Labeling
          </button>
          <button
            class="btn-sm btn nav-justified"
            [disabled]="!enabledApproverButton"
            *ngIf="showApproverButton"
            [ngClass]="showApproverButton ? 'show-btn' : ''"
            style="width: 25%; margin-left: 20px"
            type="submit"
            (click)="RedirectToProjectAnnotation(projectDetail.id,enums.Role_Approver);"
          >
            Start Reviewing
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-md-12 col-lg-12 col-xs-12">
  <div class="row">
    <div class="tab-box" id="tab-classic" data-tab-anima="fade-right">
      <ul class="nav nav-tabs">
        <li class="current-active active show-tab"><a href="#">Overview</a></li>
        <li class="show-tab"><a href="#">Images</a></li>
        <li class="show-tab" *ngIf="!isAdmin();">
          <a href="#">Active Time</a>
        </li>
        <li class="show-tab" *ngIf="isAdmin();">
          <a href="#">Members</a>
        </li>
        <li class="show-tab" *ngIf="isAdmin();">
          <a href="#">Stats</a>
        </li>
        <li class="show-tab" *ngIf="isAdmin();">
          <a href="#">Max Count</a>
        </li>
      </ul>
      <div
        class="panel active fade-right"
        style="
          animation-duration: 300ms;
          transition-timing-function: ease;
          transition-delay: 0ms;
        "
      >
        <div
          class="section-bg-color bg-color white"
          *ngIf="currentRole == enums.Role_Admin"
        >
          <div class="container content">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="icon-box counter-box-icon">
                        <div class="icon-box-cell">
                          <i class="im-arrow-around text-xl"></i>
                        </div>
                        <div class="icon-box-cell">
                          <label
                            class="counter text-l"
                            data-speed="5000"
                            data-to="1203"
                            data-trigger="null"
                            >{{projectDetail.total_images}}</label
                          >
                          <p>Total Images</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="icon-box counter-box-icon">
                        <div class="icon-box-cell">
                          <i class="im-archery-2 text-xl"></i>
                        </div>
                        <div class="icon-box-cell">
                          <label
                            class="counter text-l"
                            data-speed="5000"
                            data-to="50020"
                            data-trigger="null"
                            >
                            {{ currentLabelerUserStats == 0 ? 0 : currentLabelerUserStats }}
                            </label>
                          <p>Labeled</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="icon-box counter-box-icon">
                        <div class="icon-box-cell">
                          <i class="fa fa-times text-xl"></i>
                        </div>
                        <div class="icon-box-cell">
                          <label
                            class="counter text-l"
                            data-speed="5000"
                            data-to="50020"
                            data-trigger="null"
                            >
                            {{ currentDisapproverUserStats == 0 ? 0 : currentDisapproverUserStats }}
                            </label>
                          <p>Disapproved</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="icon-box counter-box-icon">
                        <div class="icon-box-cell">
                          <i class="fa fa-thumbs-up text-xl"></i>
                        </div>
                        <div class="icon-box-cell">
                          <label
                            class="counter text-l"
                            data-speed="5000"
                            data-to="1203"
                            data-trigger="null"
                            >{{ currentApproverUserStats == 0 ? 0 : currentApproverUserStats }}</label
                          >
                          <p>Approved</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="icon-box counter-box-icon">
                        <div class="icon-box-cell">
                          <i class="text-xl im-contrast text-xl"></i>
                        </div>
                        <div class="icon-box-cell">
                          <label
                            class="counter text-l"
                            data-speed="5000"
                            data-to="1000"
                            data-trigger="null"
                            >{{(projectDetail.total_annotated_images/projectDetail.total_images*100).toFixed(2)
                            }}%</label
                          >
                          <p>Completed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="section-bg-color bg-color white"
          *ngIf="currentRole == enums.Role_Labeler"
        >
          <div class="container content">
            <div class="row">
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="fa fa-times text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        class="counter text-l"
                        data-speed="5000"
                        data-to="1203"
                        data-trigger="null"
                        > {{ currentDisapproverUserStats == 0 ? 0 : currentDisapproverUserStats }}
                        </label>
                      <p>Disapproved</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="fa fa-thumbs-up text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        class="counter text-l"
                        data-speed="5000"
                        data-to="50020"
                        data-trigger="null"
                        >{{currentApproverUserStats == 0 ? 0: currentApproverUserStats }}</label
                      >
                      <p>Approved</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="im-archery-2 text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        class="counter text-l"
                        data-speed="5000"
                        data-to="1203"
                        data-trigger="null"
                        >{{ currentLabelerUserStats == 0 ? 0: currentLabelerUserStats }}</label
                      >
                      <p>Total Labeled</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="im-coins text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        class="counter text-l"
                        data-speed="5000"
                        data-to="7523"
                        data-trigger="null"
                        >{{projectDetail.total_images == 0 ? 0: projectDetail.total_images }}</label>
                      <p>Total Images</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="section-bg-color bg-color white"
          *ngIf="currentRole == enums.Role_Approver"
        >
          <div class="container content">
            <div class="row">
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="im-arrow-around text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        *ngIf="currentApproverUserStats"
                        class="counter text-l"
                        data-speed="5000"
                        data-to="1203"
                        data-trigger="null"
                        >{{ currentApproverUserStats == 0 ? 0: currentApproverUserStats }}</label
                      >
                      <p>Approved</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="fa fa-times text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        class="counter text-l"
                        data-speed="5000"
                        data-to="50020"
                        data-trigger="null"
                        >{{ currentDisapproverUserStats == 0 ? 0 : currentDisapproverUserStats }}</label
                      >
                      <p>Disapproved</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="im-archery-2 text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        class="counter text-l"
                        data-speed="5000"
                        data-to="1203"
                        data-trigger="null"
                        >{{ currentTotalReviewStats == 0 ? 0 : currentTotalReviewStats }}</label
                      >
                      <p>Total Reviewed</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="icon-box counter-box-icon">
                    <div class="icon-box-cell">
                      <i class="im-coins text-xl"></i>
                    </div>
                    <div class="icon-box-cell">
                      <label
                        class="counter text-l"
                        data-speed="5000"
                        data-to="7523"
                        data-trigger="null"
                        >{{projectDetail.total_images}}</label
                      >
                      <p>Total Images</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container2">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row rowalign">
              <table
                class="table table-condensed table-hover table-striped bootgrid-table"
                aria-busy="false"
              >
                <thead class="custom-table-header">
                  <tr>
                    <th class="text-left">
                      <a
                        href="javascript:void(0);"
                        class="column-header-anchor sortable"
                      >
                        <span class="text">Name</span>
                        <span class="icon fa"></span>
                      </a>
                    </th>
                    <th class="text-left">
                      <a
                        href="javascript:void(0);"
                        class="column-header-anchor sortable"
                      >
                        <span class="text">No. of Images</span>
                        <span class="icon fa"></span>
                      </a>
                    </th>
                    <th
                      class="text-left"
                      *ngIf="isAdmin();"
                    >
                      <a
                        href="javascript:void(0);"
                        class="column-header-anchor sortable"
                      >
                        <span class="text">Action</span>
                        <span class="icon fa"></span>
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="projectDetail" >
                  <tr
                    *ngFor="let data of projectDetail.data_set_details"
                    id="{{data.id}}"
                  >
                    <td class="text-left">{{data.name}}</td>
                    <td class="text-left">{{data.number_of_images}}</td>
                    <td
                      class="text-left"
                      *ngIf="isAdmin();"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn-danger"
                        (click)="DetachDataset(data.id);"
                        >Detach</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="panel fade-right"
        style="
          animation-duration: 300ms;
          transition-timing-function: ease;
          transition-delay: 0ms;
        "
      >
        <div id="lightboxModal" class="lightbox-hide">
          <div class="lightbox-modal">
            <div class="lightbox-container">
              <span (click)="closeModal();">x</span>
              <img src="{{lightboxImageFile}}" />
              <div class="lightbox-values">
                <div class="row">
                  <div class="col-md-6">
                    <strong>ID:</strong> {{lightboxId}}
                  </div>
                  <div class="col-md-6">
                    <strong>Width:</strong> {{lightboxWidth}}
                  </div>
                  <div class="col-md-6">
                    <strong>Height:</strong> {{lightboxHeight}}
                  </div>
                  <div class="col-md-6">
                    <strong>Width:</strong> {{lightboxWidth}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 maso-list gallery">
                    <div class="row"> -->
        <div
          class="col-xs-12 col-sm-4 col-md-3 col-lg-2"
          *ngFor="let data of arrProjectImages"
        >
          <div class="dataset-images">
            <img src="{{data.image_file}}" (click)="openModal(data.id);" />
          </div>
          <!-- <a class="img-box lightbox" data-lightbox-anima="show-scale" href="{{data.image_file}}">
                                <img src="{{data.image_file}}" alt="">
                            </a> -->
        </div>
        <div class="clear"></div>
        <div [ngClass]="showLoadMore ? 'list-nav' : 'list-nav hidden'">
          <div class="text-center">
            <a
              href="#"
              (click)="LoadMoreRecords()"
              class="btn btn-sm circle-button load-more-maso"
              data-page-items="8"
              data-current-page="1"
              style="display: inline-block"
              >Load More <i class="fa fa-arrow-down"></i
            ></a>
          </div>
        </div>
        <!-- <div class="row">
                    <div class="list-nav">
                        <a href="#" class="btn btn-sm circle-button load-more-maso" data-page-items="8"
                            data-current-page="1" style="display: inline-block;">Load More <i
                                class="fa fa-arrow-down"></i></a>
                    </div>
                </div> -->
      </div>

      <div
      *ngIf="!isAdmin();"
      class="panel fade-right"
      style="
        animation-duration: 300ms;
        transition-timing-function: ease;
        transition-delay: 0ms;
      "
    >
      <div
        class="section-bg-color bg-color white"
        *ngIf="currentRole == enums.Role_Labeler || currentRole == enums.Role_Approver"
      >
        <div class="container content">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="icon-box counter-box-icon">
                  <div class="icon-box-cell">
                    <i class="fa fa-clock-o text-xl"></i>
                  </div>
                  <div class="icon-box-cell">
                    <label
                      class="counter text-l"
                      data-speed="5000"
                      data-to="1203"
                      data-trigger="null"
                      > {{ currentUserTotalActiveTimeStats == 0 ? 0 : currentUserTotalActiveTimeStats}}
                      </label>
                    <p>Total Time</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="icon-box counter-box-icon">
                  <div class="icon-box-cell">
                    <i class="fa fa-clock-o text-xl"></i>
                  </div>
                  <div class="icon-box-cell">
                    <label
                      class="counter text-l"
                      data-speed="5000"
                      data-to="50020"
                      data-trigger="null"
                      >{{ currentUserAverageActiveTimeStats == 0 ? 0 : currentUserAverageActiveTimeStats }}</label
                    >
                    <p>Average Time</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

      <!-- </div>
                </div> -->
      <div
        class="panel fade-right"
        style="
          animation-duration: 300ms;
          transition-timing-function: ease;
          transition-delay: 0ms;
        "
      >
        <div class="section-empty section-item">
          <div class="section-bg-color">
            <div class="container content">
              <form
                class="form-box form-ajax form-inline"
                [formGroup]="projectDetialForm"
                (ngSubmit)="AddProjectUser()"
              >
                <div class="row">
                  <div class="col-md-3">
                    <select
                      name="email"
                      id="email"
                      placeholder="User Role"
                      class="form-control form-value"
                      formControlName="email"
                      [ngClass]="{ 'is-invalid': submitted && projectDetialForm.controls.email.errors }"
                      (change)="SelectUser();"
                    >
                      <option value="">-- Select User --</option>
                      <option
                        value="{{data.id}}"
                        *ngFor="let data of arrNonProjectUsers"
                      >
                        <span style="color: red">{{data.name}}</span>
                        ({{data.email}})
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && projectDetialForm.controls.email.errors"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="projectDetialForm.controls.email.errors.required"
                      >
                        Email is required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <select
                      name="userRole"
                      id="userRole"
                      placeholder="User Role"
                      class="form-control form-value disabled"
                      formControlName="userRole"
                      [ngClass]="{ 'is-invalid': submitted && projectDetialForm.controls.userRole.errors }"
                    >
                      <option value="">-- Select Role --</option>
                      <option
                        value="{{data.id}}"
                        *ngFor="let data of arrAllRoles"
                      >
                        {{data.name}}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && projectDetialForm.controls.userRole.errors"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="projectDetialForm.controls.userRole.errors.required"
                      >
                        Role is required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 text-r">
                    <button class="btn-sm btn nav-justified" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </form>
              <hr class="space s" />
            </div>
          </div>

          <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 50px">
            <div class="row rowalign">
              <table
                class="table table-condensed table-hover table-striped bootgrid-table"
                aria-busy="false"
              >
                <thead class="custom-table-header">
                  <tr>
                    <th class="text-left">
                      <a
                        href="javascript:void(0);"
                        class="column-header-anchor sortable"
                      >
                        <span class="text">Name</span>
                        <span class="icon fa"></span>
                      </a>
                    </th>
                    <th class="text-left">
                      <a
                        href="javascript:void(0);"
                        class="column-header-anchor sortable"
                      >
                        <span class="text">Email</span>
                        <span class="icon fa"></span>
                      </a>
                    </th>
                    <th class="text-left">
                      <a
                        href="javascript:void(0);"
                        class="column-header-anchor sortable"
                      >
                        <span class="text">Role</span>
                        <span class="icon fa"></span>
                      </a>
                    </th>
                    <th class="text-left">
                      <a
                        href="javascript:void(0);"
                        class="column-header-anchor sortable"
                      >
                        <span class="text">Action</span>
                        <span class="icon fa"></span>
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of arrAllProjectActiveUsers">
                    <td *ngIf="data.is_active === true" class="text-left">{{data.user_details.name}}</td>
                    <td *ngIf="data.is_active === true" class="text-left">{{data.user_details.email}}</td>
                    <td *ngIf="data.is_active === true" class="text-left">{{data.role_details.name}}</td>
                    <td
                      class="text-left"
                      *ngIf="isAdmin() && data.user_details.user_type != 1 && data.is_active === true"
                    >
                      <a
                        href="javascript:void(0);"
                        class="btn-danger"
                        (click)="DeleteUserFromProject(
                          data.user_details.id, 
                          data.user_details.user_type,
                          data.project,
                          data.user_details.email,
                          data.id,
                          false,
                          true
                          )"
                        >Delete</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="panel fade-right"
        style="
          animation-duration: 300ms;
          transition-timing-function: ease;
          transition-delay: 0ms;
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div
                class="tab-box"
                id="tab-classic2"
                data-tab-anima="fade-right"
              >
                <ul class="nav nav-tabs">
                  <li class="current-active active"><a href="#">Labeler</a></li>
                  <li class=""><a href="#">Approval</a></li>
                  <li class=""><a href="#">Skipped</a></li>
                  <li class=""><a href="#">Rejected</a></li>
                </ul>
                <div
                  class="panel active fade-right"
                  style="
                    animation-duration: 300ms;
                    transition-timing-function: ease;
                    transition-delay: 0ms;
                  "
                >
                  <div class="container">
                    <div class="col-md-12">
                      <div class="row">
                        <table
                          class="table table-condensed table-hover table-striped bootgrid-table"
                          aria-busy="false"
                        >
                          <thead class="custom-table-header">
                            <tr>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Labeler Name</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Labeler Email</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Labeled Images</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Disapproved Images</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Approved Images</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                            </tr>
                          </thead>
                          <tbody *ngIf="arrAllProjectUsers">
                            <tr
                              *ngFor="let data of arrAllProjectUsers"
                            >
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 2" class="text-left">{{data.user_details.name}}</td>
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 2" class="text-left">
                                <a href="`javascript:void(0);`"
                                  >{{data.user_details.email}}</a
                                >
                              </td>
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 2" class="text-left">{{ getLabelerAndReviwerCountForAdmin(data.user_details.id, 'labeled' )}}</td>
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 2" class="text-left">{{ getLabelerAndReviwerCountForAdmin(data.user_details.id, 'disapproved' )}}</td>
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 2" class="text-left">{{ getLabelerAndReviwerCountForAdmin(data.user_details.id, 'approved' )}}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          style="margin-top: 80px;"
                          class="table table-condensed table-hover table-striped bootgrid-table"
                          aria-busy="false"
                        >
                          <thead class="custom-table-header">
                            <tr>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Approver Name</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Approver Email</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Approved Images</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Disapproved Images</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                            </tr>
                          </thead>
                          <tbody *ngIf="arrAllProjectUsers">
                            <tr
                              *ngFor="let data of arrAllProjectUsers"
                            >
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 3" class="text-left">{{data.user_details.name}}</td>
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 3" class="text-left">
                                <a href="`javascript:void(0);`"
                                  >{{data.user_details.email}}</a
                                >
                              </td>
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 3" class="text-left">{{ getLabelerAndReviwerCountForAdmin(data.user_details.id, 'approved' )}}</td>
                              <td [ngClass]="{'deleted-user': data.is_active === false }" *ngIf="data.user_details.user_type == 3" class="text-left">{{ getLabelerAndReviwerCountForAdmin(data.user_details.id, 'disapproved' )}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="panel fade-right"
                  style="
                    animation-duration: 300ms;
                    transition-timing-function: ease;
                    transition-delay: 0ms;
                  "
                >
                  <div class="container">
                    <div class="col-md-12">
                      <div class="row">
                        <table
                          class="table table-condensed table-hover table-striped bootgrid-table"
                          aria-busy="false"
                        >
                          <thead class="custom-table-header">
                            <tr>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Name</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">Email</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                              <th class="text-left">
                                <a
                                  href="javascript:void(0);"
                                  class="column-header-anchor sortable"
                                >
                                  <span class="text">No. of Images</span>
                                  <span class="icon fa"></span>
                                </a>
                              </th>
                            </tr>
                          </thead>
                          <tbody *ngIf="projectStats">
                            <tr
                              *ngFor="let rejecter of projectStats.rejected_data"
                            >
                              <td class="text-left">{{rejecter.name}}</td>
                              <td class="text-left">
                                <a href="`javascript:void(0);`"
                                  >{{rejecter.email}}</a
                                >
                              </td>
                              <td class="text-left">{{rejecter.count}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
      class="panel fade-right"
      style="
        animation-duration: 300ms;
        transition-timing-function: ease;
        transition-delay: 0ms;
      "
    >
    <div class="container2">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="row rowalign">
          <table
            class="table table-condensed table-hover table-striped bootgrid-table"
            aria-busy="false"
          >
            <thead class="custom-table-header">
              <tr>
                <th class="text-left">
                  <a
                    href="javascript:void(0);"
                    class="column-header-anchor sortable"
                  >
                    <span class="text">Initial Max Count L/R</span>
                    <span class="icon fa"></span>
                  </a>
                </th>
                <th class="text-left">
                  <a
                    href="javascript:void(0);"
                    class="column-header-anchor sortable"
                  >
                    <span class="text">No. of Images</span>
                    <span class="icon fa"></span>
                  </a>
                </th>
                <th
                  class="text-left"
                  *ngIf="isAdmin();"
                >
                  <a
                    href="javascript:void(0);"
                    class="column-header-anchor sortable"
                  >
                    <span class="text">Max labeler</span>
                    <span class="icon fa"></span>
                  </a>
                </th>
                <th
                class="text-left"
                *ngIf="isAdmin();"
              >
                <a
                  href="javascript:void(0);"
                  class="column-header-anchor sortable"
                >
                  <span class="text">Max Reviewer</span>
                  <span class="icon fa"></span>
                </a>
              </th>
              </tr>
            </thead>
            <tbody *ngIf="projectDetail" >
              <tr
                *ngFor="let data of projectDetail.data_set_details"
                id="{{data.id}}"
              >
                <td class="text-left">{{this.initialMaxCountOne}} / {{this.initialMaxCountTwo}}</td>
                <td class="text-left">{{this.allimages}}</td>
                <td class="text-left">{{this.max_count_labeler}}</td>
                <td class="text-left">{{this.max_count_reviewer}}</td>
                <!-- <td
                  class="text-left"
                  *ngIf="isAdmin();"
                ><a
                  href="javascript:void(0);"
                   class=""
                  >{{this.maxcount}}</a>
                </td> -->
              </tr>
            </tbody>
          </table>
          <fieldset>
            <legend style="margin-top: 20px;">Max Count</legend>
          <form (submit)="setmaxcount(max_input_count_labeler.value, max_input_count_approver.value)">
          <input type="number" [required]="max_input_count_labeler.value" required id="maxcount" value="{{this.maxcountlabeler}}" name="maxcount" #max_input_count_labeler  placeholder="For Labeler"><br><br>
          <input type="number" id="maxcounttwo" [required]="max_input_count_approver.value" value="{{this.maxcountreviewer}}" required name="maxcount" #max_input_count_approver  placeholder="For Reviewer"><br><br>
          <button class="btn btn-primary">Submit MAXCOUNT</button>
        </form>
      </fieldset>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>
</div>
