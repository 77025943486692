import { Injectable } from '@angular/core';
import Toastify from 'toastify-js';
import { Enums } from '@app/_models';

declare var moment: any;
@Injectable({ providedIn: 'root' })
export class Utility {

  DisplayException(message: string, type: Enums){
    let bgColors =''
    if(type == Enums.Message_Polygons_draw){
     bgColors = 'linear-gradient(to right, #b00000, #e8e217)';
    }
    Toastify({
      text: message,
      duration: 2000,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColors,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }

  DisplayUploadMessage(message: string, type: Enums){
    let bgColors =''
    if(type == Enums.Message_Dataset_Created){
     bgColors = 'linear-gradient(to right, #b00000, #e8e217)';
    }
    Toastify({
      text: message,
      duration: 1000,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColors,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }

  DisplayUploadDone(message: string, type: Enums){
    let bgColors =''
    if(type == Enums.Message_Upload_Error){
     bgColors = 'linear-gradient(to right, #b00000, #e8e217)';
    }
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColors,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }

  Displayprojectuserexist(message: string, type: Enums){
    let bgColors =''
    if(type == Enums.Message_Projectuser_Exist){
     bgColors = 'linear-gradient(to right, #b00000, #e8e217)';
    }
    Toastify({
      text: message,
      duration: 2000,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColors,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }
  DisplayGreaterLabelerCount(message: string, type: Enums){
    let bgColors =''
    if(type == Enums.Message_Labeler_Greater){
     bgColors = 'linear-gradient(to right, #b00000, #e8e217)';
    }
    Toastify({
      text: message,
      duration: 1000,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColors,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }
  DisplayLesserLabelerCount(message: string, type: Enums){
    let bgColors =''
    if(type == Enums.Message_Labeler_Lesser){
     bgColors = 'linear-gradient(to right, #b00000, #e8e217)';
    }
    Toastify({
      text: message,
      duration: 1000,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColors,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }
  DisplayDataSetMessage(message: String, type: Enums, time: any,  color: any) {
    let bgColor = '';
    if (type == Enums.Message_Type_Sucess) {
      bgColor = color;
    } else if (type == Enums.Message_Type_Error) {
      bgColor = color;
    }
    Toastify({
      text: message,
      duration: time,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColor,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }
  
  DisplayMessage(message: String, type: Enums) {
    let bgColor = '';
    if (type == Enums.Message_Type_Sucess) {
      bgColor = 'linear-gradient(to right, #00b09b, #96c93d)';
    } else if (type == Enums.Message_Type_Error) {
      bgColor = 'linear-gradient(to right, #b00000, #e8e217)';
    }
    Toastify({
      text: message,
      duration: 10000,
      gravity: 'top', // `top` or `bottom`
      position: 'left', // `left`, `center` or `right`
      backgroundColor: bgColor,
      stopOnFocus: true, // Prevents dismissing of toast on hover
      onClick: function () {}, // Callback after click
    }).showToast();
  }
  DateDifferenceFromToday(date: String) {
    //var starts = moment('2014-02-03 12:53:12');
    var starts = moment(date);
    var ends = moment();

    var duration = moment.duration(ends.diff(starts));

    var diff = moment.preciseDiff(starts, ends, true);
    // example: { "years": 2, "months": 7, "days": 0, "hours": 6, "minutes": 29, "seconds": 17, "firstDateWasLater":  false }
    return diff;
    // or as string:
    var diffHuman = moment.preciseDiff(starts, ends);
  }
  IDGenerator = () => {
    var length = 8;
    var timestamp = +new Date();

    var _getRandomInt = function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    var ts = timestamp.toString();
    var parts = ts.split('').reverse();
    var id = '';

    for (var i = 0; i < length; ++i) {
      var index = _getRandomInt(0, parts.length - 1);
      id += parts[index];
    }

    return id;
  };

  /**
  * Gets random number/charatcer
  * @param length
  * @returns random STRING
  */
  getRandomString(length: Number){
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
   }
   return result;
  }
}
