import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';

import { environment } from '@environments/environment';

import { Observable } from 'rxjs';
import { Enums } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AnnotationService {
  constructor(private http: HttpClient) {}

  GetProjectClassfiers(project_id: String) {
    return this.http.get(`${environment.apiUrl}/classifier/?project=${project_id}`);
  }
  SubmitProjectClassifiers(data:any,project_id:any,data_set_image:any){
    return this.http.post(`${environment.apiUrl}/classifier-answer/?project=${project_id}&data_set_image=${data_set_image}`,data);
  }
  SubmitProjectAnnotation(data:any,annotation_answer_id:Number){
    return this.http.patch(`${environment.apiUrl}/annotation/${annotation_answer_id}/`,data);
  }
  SubmitActiveTimeLogService(status:any,data_set_image_id:Number,project_id:Number,user_id:Number, active_time:any){
    return this.http.post(`${environment.apiUrl}/annotation_activetime_log/`, {
      status: status,
      project: project_id,
      data_set_image: data_set_image_id,
      user: user_id,
      active_time: active_time }
    );
  }
  SubmitAnnotationStatsService(status:any,data_set_image_id:Number,project_id:Number,perform_by?:Number, perform_for?:Number){
    return this.http.post(`${environment.apiUrl}/annotation_add_stats/`, {
      status: status,
      project: project_id,
      data_set_image: data_set_image_id,
      perform_by: perform_by,
      perform_for: perform_for }
    );
  }
}
