import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatasetService } from '@app/_services';
import { Utility } from '@app/_helpers';
import { Enums } from '@app/_models';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({ templateUrl: 'dataset-new.html' })
export class DatasetNewComponent implements OnInit {
  loading = false;
  datasetForm: FormGroup;
  submitted = false;
  dataSaved = false;
  dataset_id = 0;
  datasetRedirection = false;
  fileUploadCounter = 0;
  fileList:any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private datasetService: DatasetService,
    private utility: Utility
  ) {}

  ngOnInit() {
    this.datasetForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
    });
    eval('ReadyEvent()');
  }

  datasetListingRedirection() {
    this.router.navigate(['/dataset/listing']);
  }

  onSubmit() {
    this.SaveAllDataset();
    return;
  }

  SaveDataset(){
    if (
      this.datasetForm.status.toLowerCase() ==
      Enums.Form_Status_Valid.toLowerCase()
    ) {
      this.datasetService.createDataset(this.datasetForm.value).subscribe(
        (response: any) => {
          if ('id' in response || (response.id != 0 && response.id != null)) {
            
            this.utility.DisplayDataSetMessage(
              Enums.Message_Dataset_Created,
              Enums.Message_Type_Sucess,
              1000,
              'linear-gradient(to right, #00b09b, #96c93d)'
            );

            this.dataSaved = true;
            this.dataset_id = response.id;
            this.UploadImagesToServer(response.id);

            // this.utility.DisplayDataSetMessage(
            //   Enums.Message_Dataset_Redirection,
            //   Enums.Message_Type_Sucess,
            //   4000,
            //   'linear-gradient(to right, #b00000, #e8e217)'
            // );

            // setTimeout(() => {
            //   this.router.navigate(['/dataset/listing']);
            // }, 4000);
            

          } else {
            this.utility.DisplayMessage(
              Enums.Message_Failure,
              Enums.Message_Type_Error
            );
          }
          this.loading = false;
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
    }

    this.submitted = true;
  }

  UploadDatasetImages(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let imageSizeCount = 0;

    let fileList: FileList | null = element.files;
    this.fileList = fileList;
    
    return;
    
  }

  UploadImagesToServer(datasetId){
    let  fileList = this.fileList;
    for (var i = 0; i < fileList.length; i++) {
      let imageName = fileList[i].name;
      let imageSize = fileList[i].size / 1024; // image file size in KB

      let formData = new FormData();
      formData.append('image_file', fileList[i]);
      formData.append('mime_type', fileList[i].type);
      formData.append('name', fileList[i].name);
      formData.append('height', '100');
      formData.append('width', '100');
      formData.append('data_set', datasetId.toString());

      // let totalImageSizeCount = (imageSizeCount += imageSize);

      // if filesize is greater than 250MB
      // if (totalImageSizeCount > Enums.Dataset_Accumulative_Filesize) {
      //   console.log('dataset accumulative filesize', imageSize);
      //   this.utility.DisplayMessage(
      //     Enums.Failure_Accumulative_Filesize,
      //     Enums.Message_Type_Error
      //   );
      //   this.RenderImageSizeList(imageName);
      // }

      // if file size is greater than 5MB
      // if (imageSize > Enums.Dataset_Single_Filesize) {
      //   console.log('dataset single filesize: ', imageSize);
      //   this.utility.DisplayMessage(
      //     Enums.Filesize_Failure,
      //     Enums.Message_Type_Error
      //   );
      //   this.datasetRedirection = true;
      //   this.RenderImageSizeList(imageName);
      // }

      // if (imageSize > Enums.Dataset_Single_Filesize) {
      //   this.utility.DisplayMessage(
      //     Enums.Filesize_Failure,
      //     Enums.Message_Type_Error
      //   );
      //   this.datasetRedirection = true;
      //   this.RenderImageSizeList(imageName);
      // } else {
      this.datasetService.createDatasetImage(formData).subscribe(
        (event) => {
          if (event.type == HttpEventType.UploadProgress) {
            console.log('uploadprogress');

            const percentDone = Math.round((100 * event.loaded) / event.total);
            // console.log(`File is ${percentDone}% loaded.`);
            this.RenderImageList(imageName, imageSize, percentDone);
          } else if (event instanceof HttpResponse) {
            // console.log('File is completely loaded!');
          }
          if (event.type === HttpEventType.Response) {
            this.fileUploadCounter += 1;
            if (this.fileUploadCounter === fileList.length) {
              // this.router.navigate(['/dataset/listing']);
            }
            // if (!this.datasetRedirection) {
            //   this.router.navigate(['/dataset/listing']);
            // }
          }
        },
        (err) => {
          console.log('Upload Error:', err);
        },
        () => {

          console.log('Upload done');
          //this.router.navigate(['/dataset/listing']);
        }
      );
      // }
    }
  }

  SaveAllDataset()
  {
    if(this.fileList != null && this.fileList.length>0)
    {
      this.SaveDataset();
    }
    else
    {
      this.utility.DisplayMessage(
        Enums.Message_Upload_Error,
        Enums.Message_Type_Error
      );
    }

  }

  RenderImageList(imageName: String, imageSize: Number, Percentage: Number) {
    // let containerId = imageName.replace('.', '');
    // let containerId = imageName.replace(/[<>()\s.:"\/\\|?*]+/g, '');
    // containerId = imageName.replace(/^[0-9]+/g, '');

    // const specialChar = new RegExp(/[<>().:"\/\\|?*]+/g);
    // const numStart = new RegExp(/^[0-9]+/g);
    // const finalRegexp = new RegExp(specialChar.source + '|' + numStart.source);
    // let containerId = imageName.replace(finalRegexp, '');
    let nonAlphanumeric = imageName.replace(/[^A-Za-z0-9]+/g, '');
    let containerId = nonAlphanumeric.replace(/^[0-9]+/g, '');

    let selector = document
      .getElementById('containerImageUploading')
      .querySelector('.uploadSingleImageContainer#' + containerId);

    if (selector != null) {
      $(`#${containerId} .progress-bar`).css('width', `${Percentage}%`);
      $(`#${containerId} .counter`).text(Percentage);
    } else {
      $('#containerImageUploading').append(`
      <div class="col-md-12 mt20 uploadSingleImageContainer" id="${containerId}">
      <div class="row vertical-row">
          <div class="col-md-3">
              <p class="progress-label ellipsis">${imageName}</p>
          </div>
          <div class="col-md-9">
              <div class="progress">
                  <div class="progress-bar" data-progress="${Percentage}" data-trigger="null" style="width: ${Percentage}%;">
                      <span><span class="counter" data-to="${Percentage}" data-trigger="null">${Percentage}</span> %</span>
                  </div>
              </div>
          </div>
        </div>
      </div>`);
    }
  }

  RenderImageSizeList(imageName) {
    let containerId = imageName.replace(/[<>().:"\/\\|?*]+/g, '');
    $('#containerImageUploading').append(`
      <div class="col-md-12 mt20 uploadSingleImageContainer filesize-exceeded" id="${containerId}">
      <div class="row vertical-row">
          <div class="col-md-3">
              <p class="progress-label ellipsis">${imageName}</p>
          </div>
          <div class="col-md-9">
          <div class="progress">
            <span>${Enums.Filesize_Failure}</span>
          </div>
          </div>
        </div>
      </div>`);
  }
}
