<div class="timespentarea">
  <div id="TimeArea">
    Time Spent: {{ timeSpentOnPage }} <span>sec</span>
  </div>
</div>
<div>
<div class="section-bg-color-3">
  <div class="col-md-12 col-sm-12 section-bg-color-1">
    <div class="top-menu-strip">
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Zoom In/Out">
          <i class="fa fa-search-plus fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="inputtagstyle" id="zoom" name="zoom" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width" (click)="EnableSelection();"> 
        <div class="inputstyle" title="Grey Scale">
          <i class="uil uil-focus-target fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="rangestyle" id="gs" name="gs" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Brightness">
          <i class="uil uil-brightness-half fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="rangestyle" id="br" name="br" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Hue">
          <i class="uil uil-palette fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input id="huer" name="huer" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Opacity">
          <i class="uil uil-circle-layer fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input id="opacity" name="opacity" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Invert">
          <i class="uil uil-th-slash fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="inputtagstyle" id="invert" name="invert" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Blur">
          <i class="uil uil-tear fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="inputtagstyle" id="blur" name="blur" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Contrast">
          <i class="uil uil-exposure-increase fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="inputtagstyle" id="ct" name="ct" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Saturate">
          <i class="uil uil-brightness fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="inputtagstyle" id="saturate" name="saturate" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <div class="list-item list-item-width">
        <div class="inputstyle" title="Sepia">
          <i class="uil uil-brush-alt fontstyle" aria-hidden="true"></i>
        </div>
        <div class="slider-container">
          <input class="inputtagstyle" id="sepia" name="sepia" type="range" min="0" max="100" value="0" />
        </div>
      </div>
      <button class="btn-sm btn btn-top-strip" type="button" (click)="SubmitData(null);">{{ currentRole == _enums.Role_Labeler ? 'Submit' : 'Approve' }}</button>
      <button class="btn-sm btn btn-top-strip" type="button" *ngIf="currentRole == _enums.Role_Approver" (click)="DisapproveAnnotation();">Disapprove</button>
      <button class="btn-sm btn btn-top-strip" type="button" *ngIf="currentRole == _enums.Role_Labeler" (click)="SkipImage();">Skip</button>
      <button class="btn-sm btn btn-top-strip" type="button" *ngIf="currentRole == _enums.Role_Approver" (click)="RejectImage();">Reject</button>
    </div>
  </div>
  </div>
</div>
<div class="labeling-details">
<div class="col-md-12 labeler-data-container" *ngIf="currentLabelerInfo != null">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="labeler-data">
        <span class="labeler-key">Labeler Id:</span>
        <span class="labeler-value">{{currentLabelerInfo.id}}</span>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="labeler-data">
        <span class="labeler-key">Labeler Name:</span>
        <span class="labeler-value">{{currentLabelerInfo.name}} ({{currentLabelerInfo.email}})</span>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="labeler-data">
        <span class="labeler-key">Image Id:</span>
        <span class="labeler-value">{{currentImageInfo.id}}</span>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="labeler-data">
        <span class="labeler-key">Image Name:</span>
        <span class="labeler-value">{{currentImageInfo.path}}</span>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="labeler-data">
        <span class="labeler-key">Dataset Id:</span>
        <span class="labeler-value">{{currentDatasetInfo.id}}</span>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="labeler-data">
        <span class="labeler-key">Dataset Name:</span>
        <span class="labeler-value">{{currentDatasetInfo.name}}</span>
      </div>
    </div>
  </div>
</div>
</div>
<div class="leftbar">
  <div>
    <button type="button" data-toggle="collapse"  class="btn btn-primary togglebutton " aria-expanded="true">
      <i class="fa fa-bars"></i>
      <span class="sr-only">Toggle Menu</span>
    </button>
  </div>
  <div class="sidebar show">
    <a class="tool-icon drawing" id="clickone" href="#" (click)="EnablePolygonDrawing();"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
    <a class="tool-icon dragging" id="clicktwo" href="#" (click)="ShowVertexes()"><i class="fa fa-arrows"></i></a>
    <a class="tool-icon disable" href="#"><i class="fa fa-search-minus"></i></a>
    <a class="tool-icon disable" href="#"><i class="fa fa-refresh"></i></a>
    <a class="tool-icon disable" href="#"><i class="fa fa-cog"></i></a>
    <a class="tool-icon disable" href="#"><i class="fa fa-th-large"></i></a>
    <a class="tool-icon disable" href="#"><i class="fa fa-rotate-right"></i></a>
    <a class="tool-icon disable" href="#"><i class="fa fa-rotate-left"></i></a>
  </div>
</div>
<div class="">
  <div class="">
    <div class="">
      <div class="">
        <div class="">
          <div class="">
            <div class="">
              <div class="col-md-3 hidden" style="height: 800px">
                <div class="row" style="height: 50%">
                  <div class="col-md-12">
                    <div class="tab-box container3border" data-tab-anima="show-scale">
                      <ul class="nav nav-tabs nav-justified">
                        <li class="current-active active">
                          <a href="#">Annotation</a>
                        </li>
                        <li class=""><a href="#">Classifier</a></li>
                      </ul>
                      <div class="panel show-scale annotation-panel" style="
                          animation-duration: 300ms;
                          transition-timing-function: ease;
                          transition-delay: 0ms;
                        ">
                        <ul class="fa-ul text-left annotation-object-list">
                          <li>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          </li>
                          <li *ngFor="let data of arrAllPolygons; let i = index">
                            <span>Polygon {{i+1}}</span>
                            <i class="fa fa-eye iconVisibility" (click)="ChangePloygonVisibility(i);"></i>
                          </li>
                        </ul>
                      </div>
                      <div class="panel active show-scale annotation-panel" style="
                          animation-duration: 300ms;
                          transition-timing-function: ease;
                          transition-delay: 0ms;
                        ">
                        <div class="LabelingForm_inputsContainer">
                          <div
                            class="field"
                            *ngFor="let classifier of arrClassifiers"
                            id="classifier_{{classifier.id}}"
                          >
                            <div class="ui divider"></div>
                            <label class="classifier-header"
                              >{{classifier.name}}</label
                            >
                            <div class="ui divider"></div>
                            <div class="field">
                              <div class="DynamicInput_category__2gWYv"></div>
                              <div
                                class="field"
                                *ngFor="let option of classifier.classifier_option"
                              >
                                <div class="ui radio checkbox">
                                  <input
                                    class="classifier-options"
                                    name="{{classifier.name}}"
                                    readonly=""
                                    tabindex="0"
                                    type="radio"
                                    value="{{option.value}}"
                                    [attr.data-classifier-id]="classifier.id"
                                    [attr.data-classifier-option-id]="option.id"
                                  />
                                  <label>{{option.value}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Gender</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Gender" readonly="" tabindex="0" type="radio" value=""><label>Male</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Gender" readonly="" tabindex="0" type="radio" value=""><label>Female </label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Gender" readonly="" tabindex="0" type="radio" value=""><label>Other</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Age</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Age" readonly="" tabindex="0" type="radio" value=""><label>&lt;15</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Age" readonly="" tabindex="0" type="radio" value=""><label>15-24</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Age" readonly="" tabindex="0" type="radio" value=""><label>25-34</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Age" readonly="" tabindex="0" type="radio" value=""><label>35-44</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Age" readonly="" tabindex="0" type="radio" value=""><label>45-54</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Age" readonly="" tabindex="0" type="radio" value=""><label>&gt;54</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Ethnicity</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Ethnicity" readonly="" tabindex="0" type="radio" value=""><label>Asian </label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Ethnicity" readonly="" tabindex="0" type="radio" value=""><label>African </label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Ethnicity" readonly="" tabindex="0" type="radio" value=""><label>Caucasian</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Ethnicity" readonly="" tabindex="0" type="radio" value=""><label>Hispanic Middle-eastern</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Ethnicity" readonly="" tabindex="0" type="radio" value=""><label>Indian</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Ethnicity" readonly="" tabindex="0" type="radio" value=""><label>Other</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Hair color</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Hair color" readonly="" tabindex="0" type="radio" value=""><label>Blonde</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Hair color" readonly="" tabindex="0" type="radio" value=""><label>Black</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Hair color" readonly="" tabindex="0" type="radio" value=""><label>White</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Hair color" readonly="" tabindex="0" type="radio" value=""><label>Red</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Hair color" readonly="" tabindex="0" type="radio" value=""><label>Light Brown</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Hair color" readonly="" tabindex="0" type="radio" value=""><label>Dark brown</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Hair color" readonly="" tabindex="0" type="radio" value=""><label>Other</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Skin color</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin color" readonly="" tabindex="0" type="radio" value=""><label>Light</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin color" readonly="" tabindex="0" type="radio" value=""><label>Fair </label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin color" readonly="" tabindex="0" type="radio" value=""><label>Medium</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin color" readonly="" tabindex="0" type="radio" value=""><label>Medium-dark</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin color" readonly="" tabindex="0" type="radio" value=""><label>Dark</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Skin tone</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin tone" readonly="" tabindex="0" type="radio" value=""><label>Cool</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin tone" readonly="" tabindex="0" type="radio" value=""><label>Neutral</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Skin tone" readonly="" tabindex="0" type="radio" value=""><label>Warm</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Face shape</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Face shape" readonly="" tabindex="0" type="radio" value=""><label>Oval </label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Face shape" readonly="" tabindex="0" type="radio" value=""><label>Round</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Face shape" readonly="" tabindex="0" type="radio" value=""><label>Square</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Face shape" readonly="" tabindex="0" type="radio" value=""><label>Triangle</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Face shape" readonly="" tabindex="0" type="radio" value=""><label>Long</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Face shape" readonly="" tabindex="0" type="radio" value=""><label>Other</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Forehead</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Forehead" readonly="" tabindex="0" type="radio" value=""><label>Narrow</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Forehead" readonly="" tabindex="0" type="radio" value=""><label>Medium</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Forehead" readonly="" tabindex="0" type="radio" value=""><label>Wide</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Eye Color</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye Color" readonly="" tabindex="0" type="radio" value=""><label>Black</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye Color" readonly="" tabindex="0" type="radio" value=""><label>Brown</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye Color" readonly="" tabindex="0" type="radio" value=""><label>Hazel</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye Color" readonly="" tabindex="0" type="radio" value=""><label>Green</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye Color" readonly="" tabindex="0" type="radio" value=""><label>Blue</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye Color" readonly="" tabindex="0" type="radio" value=""><label>Other</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Eye shape</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye shape" readonly="" tabindex="0" type="radio" value=""><label>Straight</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye shape" readonly="" tabindex="0" type="radio" value=""><label>Up-turned</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye shape" readonly="" tabindex="0" type="radio" value=""><label>Down-turned</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Eye type</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye type" readonly="" tabindex="0" type="radio" value=""><label>Hooded</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye type" readonly="" tabindex="0" type="radio" value=""><label>Folded</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye type" readonly="" tabindex="0" type="radio" value=""><label>Protruding</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye type" readonly="" tabindex="0" type="radio" value=""><label>Mono-lid</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye type" readonly="" tabindex="0" type="radio" value=""><label>Deep-set</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye type" readonly="" tabindex="0" type="radio" value=""><label>Other</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Eye size</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye size" readonly="" tabindex="0" type="radio" value=""><label>Small</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye size" readonly="" tabindex="0" type="radio" value=""><label>Medium</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eye size" readonly="" tabindex="0" type="radio" value=""><label>Large </label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Nose brdg.</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Nose brdg." readonly="" tabindex="0" type="radio" value=""><label>Small</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Nose brdg." readonly="" tabindex="0" type="radio" value=""><label>Medium </label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Nose brdg." readonly="" tabindex="0" type="radio" value=""><label>Large</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Nose base</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Nose base" readonly="" tabindex="0" type="radio" value=""><label>Small</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Nose base" readonly="" tabindex="0" type="radio" value=""><label>Medium</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Nose base" readonly="" tabindex="0" type="radio" value=""><label>Large</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Lips type</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips type" readonly="" tabindex="0" type="radio" value=""><label>Straight</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips type" readonly="" tabindex="0" type="radio" value=""><label>Up-turned</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips type" readonly="" tabindex="0" type="radio" value=""><label>Down-turned</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Lips size</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips size" readonly="" tabindex="0" type="radio" value=""><label>Small</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips size" readonly="" tabindex="0" type="radio" value=""><label>Medium</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips size" readonly="" tabindex="0" type="radio" value=""><label>Large </label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Lips ratio</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips ratio" readonly="" tabindex="0" type="radio" value=""><label>Fuller-bottom</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips ratio" readonly="" tabindex="0" type="radio" value=""><label>Same-size</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Lips ratio" readonly="" tabindex="0" type="radio" value=""><label>Fuller-upper</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Bald</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Bald" readonly="" tabindex="0" type="radio" value=""><label>Yes</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Bald" readonly="" tabindex="0" type="radio" value=""><label>Partially</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Bald" readonly="" tabindex="0" type="radio" value=""><label>No</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Mustache</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Mustache" readonly="" tabindex="0" type="radio" value=""><label>Yes</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Mustache" readonly="" tabindex="0" type="radio" value=""><label>Partially</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Mustache" readonly="" tabindex="0" type="radio" value=""><label>No</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Beard</label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Beard" readonly="" tabindex="0" type="radio" value=""><label>Yes</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Beard" readonly="" tabindex="0" type="radio" value=""><label>Partially</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Beard" readonly="" tabindex="0" type="radio" value=""><label>No</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui divider"></div>
                                                        <label class="classifier-header">Eyewear </label>
                                                        <div class="ui divider"></div>
                                                        <div class="field">
                                                            <div class="DynamicInput_category__2gWYv"> </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eyewear " readonly="" tabindex="0" type="radio" value=""><label>Sunglasses</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eyewear " readonly="" tabindex="0" type="radio" value=""><label>Specs</label></div>
                                                            </div>
                                                            <div class="field">
                                                                <div class="ui radio checkbox"><input name="Eyewear " readonly="" tabindex="0" type="radio" value=""><label>None</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="space s" />
              </div>
              <div class="">
                <div class="">
                  <!-- <img src="../../assets/images/team-1.jpg" alt="" style="width: 30%;float: left;margin-left: 35% !important;" /> -->
                  <input type="file" id="fileInput" name="file" class="hidden" />
                  
                  <div class="annotation-container" id="containerAnnotation"></div>

                  <img id="imageSrc" alt="No Image" style="display: none" />

                  <img id="maskSrc" alt="No Image" style="display: none" />
                  <button class="hidden">Remove Mask</button>
                  <button class="hidden">Add Mask</button>
                  <button class="hidden">Export Mask</button>
                  <canvas id="imageCanvas1" class="hidden"></canvas>
                  <textarea id="coordinates" style="display: none"></textarea>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</div>
<i class="scroll-top scroll-top-mobile fa fa-sort-asc"></i>