import { Component, OnInit } from '@angular/core';
import { count, first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnnotationHelper } from '@app/_helpers';
import { AnnotationService, ProjectService } from '@app/_services';
import { RouterModule, Router, ActivatedRoute, Event, NavigationStart } from '@angular/router';
import { Utility } from '@app/_helpers';
import { Enums } from '@app/_models';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
  analyzeAndValidateNgModules,
  identifierModuleUrl,
} from '@angular/compiler';
import { HostListener } from '@angular/core';
import { AuthenticationService } from '../_services';
import { User, Role } from '../_models';

declare var cv: any;
declare var window: any;
declare var $: any;
declare var shortcut: any;
declare var d3: any;
declare var TimeMe: any;
//declare var copyimage: any;

@Component({ templateUrl: 'annotation.html' })
export class AnnotationComponent implements OnInit {
  user: User;
  constructor(
    private annotationService: AnnotationService,
    private router: Router,
    private route: ActivatedRoute,
    private utility: Utility,
    private projectService: ProjectService,
    private authenticationService: AuthenticationService
  ) {
    let _this = this;
    this.authenticationService.user.subscribe((x) => (this.user = x));

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        //Flusing the active time when moving to another page
        this.TrackTime('reset');
        
      }

      
    });

    this.timeSpentOnPage = ( typeof this.timeSpentOnPage === 'undefined' ) ? 0 : this.timeSpentOnPage;

    this._enums = Enums;
  }
  
  // Variable Initilization
  flag = false;
  dot_flag = false;
  prevX = 0;
  prevY = 0;
  currX = 0;
  currY = 0;
  color = 'black';
  thickness = 2;
  x: any = 'black';
  y = 1;
  matEmpty;
  matColored;
  maskimage;
  imgElement;
  inputElement: any;
  maskinputElement: any;

  canvasScale: any;
  copyimage: any;
  arrProjectImages: any;
  arrSubmittedImages: any;
  arrClassifiers: any;
  arrCurrentAnnotatedImageIndex: any = null;
  dataLoadingTimeout: any = 1000;
  FilteredAnnotationAnswers: any;
  currentLabelerInfo: any;
  currentDatasetInfo: any;
  currentImageInfo: any;
  currentImageWidth: any;
  currentImageHeight: any;
  perPixelFactor: any = 0.00099609375;
  currentImagePointsFactor: any;
  currentRole: any;
  _enums: any;
  svg: any;
  timeSpentOnPage: any;
  disablePolygonDrawing: any = false;
  radius: any;
  maxcountlabeler:any;
  maxcountreviewer:any;
  current_userid:any;
  labelerimagescount:any;
  approverimagescount:any;
  totallabeledimages:any;
  totalapprovedimages:any;
  projectStats:any;
  currentApproverUserStats:number;
  currentLabelerUserStats:number;
  countone:any;
  counttwo:any;

  ngAfterViewInit() {
    let _this = this;
    _this.maxcount();
    this.GetProjectStats();
  }

  ngOnInit() {
    eval('ReadyEvent()');
    this.GetProjectClassfiers();
    this.GetProjectStats();
    let currenAnnotationData = localStorage.getItem('current_annotation_data');
    this.current_userid = JSON.parse(localStorage.getItem('user')).user.id;
    /**
     * If user is trying to load the page from annotation screen, then system will
     * show the current image from local storate (if there is any data stored in local storage) 
     * instead of calling the API again
     */
    let data = window.performance.getEntriesByType("navigation")[0].type;
    if( data === "reload" && currenAnnotationData != null ){
      this.GetProjectImagesFromLocalStorage();
    }
    else{
      this.GetProjectImages("");
      this.TrackTime('reset');
    }
  
    let _this = this;
    
    let currentUserName = JSON.parse(localStorage.getItem('user')).user.name;
    let currentUserEmail = JSON.parse(localStorage.getItem('user')).user.email;
    let currentUserRole = JSON.parse(localStorage.getItem('user')).user
      .user_type;

    if (currentUserRole == Enums.Role_Admin_Id)
      this.currentRole = Enums.Role_Admin;
    else if (currentUserRole == Enums.Role_Labeler_Id)
      this.currentRole = Enums.Role_Labeler;
    else if (currentUserRole == Enums.Role_Approver_Id)
      this.currentRole = Enums.Role_Approver;

    window.addEventListener('load', function () {
      _this.RegisterShortKeys();
    });

    setTimeout(function () {
      function hashHandler() {
        this.oldHash = window.location.hash;
        this.Check;
        
        var that = this;
        var detect = function () {
          if (that.oldHash != window.location.hash) {
            if (that.oldHash.indexOf('/annotation/') > -1) {
              _this.UnLockCurrentImage('browser back');
              that.oldHash = window.location.hash;
              clearInterval(that.Check);
              this.clearLocalStorageData('current_annotation_data');
            }
          }
        };
        this.Check = setInterval(function () {
          detect();
        }, 100);
      }

      var hashDetection = new hashHandler();
    }, 1000);

    _this.imgElement = document.getElementById('imageSrc');
    _this.inputElement = document.getElementById('fileInput');
    _this.maskinputElement = document.getElementById('maskInput');
    _this.canvasScale = document.getElementById('jPolygon');

    _this.selectedToolIcon();
   /**
     * Load shortcut keys only for Annoatation Screen
     */
    let currentRouteUrl = this.router.routerState.snapshot.url;
    if( currentRouteUrl.includes('annotation')  ){
      _this.RegisterShortKeys();
    }
    _this.AddStyle();
  }

  defaulttool(){
    let _this = this;

      if(this.currentRole === this._enums.Role_Labeler){

        setTimeout( function(){
          $("#clickone").trigger('click');
          _this.EnablePolygonDrawing();
        }, 500 );
      
      }

      else if(this.currentRole === this._enums.Role_Approver)  
      {
         setTimeout(function(){  
        $("#clicktwo").trigger('click');
        _this.ShowVertexes();  
      },1500);
      } 
  }

  ngOndestroy(){
    this.TrackTime('reset');
  }
  /**
   * This function will add selected class to toolbar
   */
  selectedToolIcon(){
    $('.tool-icon').on('click', function(){
      if( $('.tool-icon').hasClass('selected') ){
        $('.tool-icon').removeClass('selected');
      }
      $(this).addClass('selected');
    });
  }

  /**
   * This function will add Style to Tool Bars and Labeling Details in the Reviewer Role
   */
  AddStyle(){
    if(this.currentRole === this._enums.Role_Approver ){
      $(".labeling-details").css(
        {
          "position":"relative",
        "top":"50px"}
        )
        }
      if(this.currentRole === this._enums.Role_Approver){      
      $(".leftbar").css(
        {
          "top": "220px",
          // "float": "left",
          "left": "0",
          "position": "fixed",
          "width": "45px"
        }
      )    
      };
      if(this.currentRole === this._enums.Role_Approver){      
        $("#containerAnnotation").css(
          {
            "top": "220px",
            "left": "45px",
            "position": "absolute",
            "background-position": "left top",
            "background-repeat": "no-repeat",
            "background-size": "cover",
            "overflow":"hidden"
          }
        )    
        }; 
    }
  /**
   * This function will add radius to vertexes
   */
  radiussize(){
    this.radius=3
    if(this.currentImageWidth<=300 || this.currentImageHeight<=300){
      this.radius=4;
    }
    else if(this.currentImageWidth<=500 || this.currentImageHeight<=500){
      this.radius=5;
    } 
    else if(this.currentImageWidth<=1000 || this.currentImageHeight<=1000){
      this.radius=6;
    }
  }

  /**
   * this function will be enabling 
   * all the available vertexes
   */
  ShowVertexes(){
    let poly = d3.select('polygon').size();
    if( poly > 0 ){
      let allVertexes = d3.selectAll('circle');
      //For toggle
      //allVertexes.classed("hide-vertex", allVertexes.classed("hide-vertex") ? false : true);
      allVertexes.classed("hide-vertex", false);

      d3.select('svg').classed("enable-dragging-icon", true);
      d3.select('svg').classed("enable-polygon-icon", false);

      /**
       * If this condition is true from partiuclar function
       * then the polygon drwaing won't be available
       */
      this.disablePolygonDrawing = true;
    }
  }

  /**
   * It will be enabling 
   * the polgon drawing on the SVG and adding its own icon +
   * If vertex dragging already selected then It will allow polygon
   * drwaing to be used
   */
  EnablePolygonDrawing(){
    let _this = this;
    _this.disablePolygonDrawing = false;
    _this.dragging = false;
    /**
     * Start initiatind drawing for labler ( drwaing for reviewer role is already 
     * initiated, so do not call it multiple times )
     */
    if( _this.currentRole == Enums.Role_Labeler ){
      _this.initializeD3('draw');
    }

    // if( $('circle').length > 0 && !$('circle').hasClass("hide-vertex") ){
    //   this.utility.DisplayMessage(
    //     Enums.Message_Disable_Vertex_Dragging,
    //     Enums.Message_Type_Error
    //   );
    //   _this.disablePolygonDrawing = true;
    // }
    // else{
      _this.drawing = true;
      // d3.selectAll('g').attr('pointer-events','none');
      d3.select('svg').classed("enable-polygon-icon", true);
      d3.select('svg').classed("enable-dragging-icon", false);
      d3.selectAll('circle').classed("hide-vertex", true);
    //}

  }

  EnableSelection(){
    console.log("drag");
    // d3.select("polygon").call(d3.behavior.drag().on("drag", function(){
    //   console.log("moving");
    // }));
    // var drag = d3.behavior.drag();
    // d3.select("g").call(drag);
    // d3.select("g").on("drag", null);

    // d3.select("g").call(d3.behavior.drag().on("drag", function(d) {
    //   d3.select("g").attr("transform", "translate(" +
    //       (d3.select("g").x = d3.event.x) + "," + (d3.select("g").y = d3.event.y) + ")")
    // }));

  }

  RegisterShortKeys() {
    let _this = this;
    let dd = Object.keys(shortcut.all_shortcuts);
    /**
     * Re-initiating/flusing the shortcut Keys, so it can be loaded
     * when moving from one component to another
     */
    dd = [];
    try {
      if (dd.length == 0) {

        shortcut.add('Ctrl+Z', function () {
          _this.undoPoints();
        });

        /**
         * Will enable dragging for complete Polygons only if the Shift Key is pressed
         */
        shortcut.add('Shift', function (e) {

          if( ! _this.isDrwaingActivatedForPolygons() ){
            d3.selectAll('.dragging').classed('disable', true);
            return;
          }

          d3.selectAll('.dragging').classed('disable', false);
          let poly = d3.selectAll('g').size();
          if( poly > 0 ){
            //let allVertexes = d3.selectAll('circle');
            //allVertexes.classed('hide-vertex', false);

            let allPolygons = d3.selectAll('g');
            let polygonClassRemoved = false;
            allPolygons.classed('make-draggable', true);
            // allPolygons.attr('pointer-events', null);
            
            /**
             * If polygon tool is selected while dragging, disable it
             */
            _this.disablePolygonDrawing = true;
            if( d3.select('svg').classed('enable-polygon-icon') ){
              d3.select('svg').classed('enable-polygon-icon', false);
              polygonClassRemoved = true;
            }
            if (e.repeat) { return }
            document.addEventListener('keyup', function () {
              allPolygons.classed('make-draggable', false);
              //allPolygons.attr('pointer-events', 'none');
              /**
               * If polygon tool is selected again while dragging, enable it
               */
               _this.disablePolygonDrawing = false;
              if( polygonClassRemoved ){
                d3.select('svg').classed('enable-polygon-icon', true); 
                }
              if( d3.select('.dragging ').classed('selected') ){
              _this.disablePolygonDrawing = true;
              }
              else{
              _this.disablePolygonDrawing = false;
              }
              });
          }
        });

        shortcut.add('Delete', function () {
          //_this.svg.select('g.drawPoly').remove();
        });
        //shortcut.add('F5', function () {
          //alert("abcd");
          //_this.UnLockCurrentImage('f5');
          // setTimeout(function () {
          //   window.location.reload();
          // }, 500);
          //return;
        //});
        //shortcut.add('ctrl+f5', function () {
         // alert("abcd");
          //_this.UnLockCurrentImage('ctrl+f5');
          // setTimeout(function () {
          //   window.location.reload();
          // }, 500);
          //return;
        //});
        // shortcut.add('C', function () {
        //   //_this.points.pop();
        // });
        shortcut.add('ESC', function () {
          if (_this.drawing) {
            var g = d3.select('g.drawPoly');
            g.select('line').remove();
          }
        });

        // shortcut.add('S', function () {
        //   _this.ShowPoints();
        // });
        //allVertexes.classed('hide-vertex', true);
      }
    } catch (error) {
      console.warn(`===============> ${error} <===============`);
    }
  }
  GetProjectClassfiers() {
    let _this = this;
    this.annotationService
      .GetProjectClassfiers(this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.arrClassifiers = response.results;

            // for Now classifiers are removed so I am marking the last options as marked

            setTimeout(() => {
              // this.arrClassifiers.forEach((element) => {
              //   element.classifier_answer.forEach((ans) => {
              //     $(
              //       `[data-classifier-option-id=${ans.classifier_option}]`
              //     ).click();
              //   });
              // });
              $('.classifier-options').click();
            }, this.dataLoadingTimeout);
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
  GetProjectImages(status: any) {
    let imageStatus = '';
    let _this = this;
    let projectId = this.route.snapshot.params['id'];
    // console.log(status);
    if (this.route.snapshot.params['action'] == Enums.Role_Labeler)
      imageStatus = Enums.Image_Type_Pending;
    else imageStatus = Enums.Image_Type_Labeled;

    /**
     * After API requestes, the main loading spinner keeps on
     * loading until unless the image and it elemets is not completely loaded.
     */
    let loding_spinner = $('.spinner-overlay');
    loding_spinner.addClass('show-spinner');

    this.projectService.getProjectImages(imageStatus, projectId).subscribe(
      (response: any) => {
        if (response != null) {
          if ('results' in response) {
            if (response.results.length > 0) {
              this.arrProjectImages = response.results;
              localStorage.setItem('current_annotation_data', JSON.stringify(this.arrProjectImages));
              this.StartAnnotation();

              setTimeout(() => {
                // image is labeled and now data is showing on svg for approver
                let arrAnnotationAnswers =
                  _this.arrProjectImages[0].annotation_answer;

                let datasetId = _this.arrProjectImages[0].id;

                let arrFilteredAnnotationAnswers = arrAnnotationAnswers.filter(
                  (obj) => {
                    if (
                      obj.project == projectId &&
                      obj.data_set_image == datasetId
                    )
                      return obj;
                  }
                );
                this.FilteredAnnotationAnswers = arrFilteredAnnotationAnswers;

                if (
                  imageStatus.toLowerCase() ==
                  Enums.Image_Type_Labeled.toLowerCase()
                ) {
                  arrFilteredAnnotationAnswers.forEach((element) => {
                    let strPolygons = element.raw_annotation_data;
                    let arrPolygons = JSON.parse(strPolygons);

                    arrPolygons.forEach((poly) => {
                      $('#containerAnnotation').imagesLoaded( { background: true }, function() {
                        _this.DrawPrePopulatePolygons(poly.toString());
                      });
                    });
                  });
                  this.currentLabelerInfo =
                    this.FilteredAnnotationAnswers[0].labeler_details;
                  this.currentDatasetInfo =
                    _this.arrProjectImages[0].dataset_details;
                  this.currentImageInfo = {
                    id: _this.arrProjectImages[0].id,
                    path: _this.arrProjectImages[0].image_file,
                  };
                }
                _this.defaulttool();
              }, this.dataLoadingTimeout);
            } else {
              this.utility.DisplayMessage(
                Enums.Message_No_Image_For_Work,
                Enums.Message_Type_Sucess
              );
            }
          } else if (response.length == 0) {
            this.utility.DisplayMessage(
              Enums.Message_No_Image_For_Work,
              Enums.Message_Type_Sucess
            );
          }
        }
      },
      (error) => {
        this.utility.DisplayMessage(
          Enums.Message_Failure,
          Enums.Message_Type_Error
        );
      }
    );  
  }
  GetProjectImagesFromLocalStorage() {
    let imageStatus = '';
    let _this = this;
    let projectId = this.route.snapshot.params['id'];

    if (this.route.snapshot.params['action'] == Enums.Role_Labeler)
      imageStatus = Enums.Image_Type_Pending;
    else imageStatus = Enums.Image_Type_Labeled;

    let currenAnnotationData: any = localStorage.getItem('current_annotation_data');
    currenAnnotationData = JSON.parse(currenAnnotationData);
    
    if (currenAnnotationData.length > 0) {
      this.arrProjectImages = currenAnnotationData;
      this.StartAnnotation();

      setTimeout(() => {
        // image is labeled and now data is showing on svg for approver
        let arrAnnotationAnswers =
          _this.arrProjectImages[0].annotation_answer;

        let datasetId = _this.arrProjectImages[0].id;

        let arrFilteredAnnotationAnswers = arrAnnotationAnswers.filter(
          (obj) => {
            if (
              obj.project == projectId &&
              obj.data_set_image == datasetId
            )
              return obj;
          }
        );
        this.FilteredAnnotationAnswers = arrFilteredAnnotationAnswers;

        if (
          imageStatus.toLowerCase() ==
          Enums.Image_Type_Labeled.toLowerCase()
        ) {
          arrFilteredAnnotationAnswers.forEach((element) => {
            let strPolygons = element.raw_annotation_data;
            let arrPolygons = JSON.parse(strPolygons);

            arrPolygons.forEach((poly) => {
              $('#containerAnnotation').imagesLoaded( { background: true }, function() {
                _this.DrawPrePopulatePolygons(poly.toString());
              });
            });
          });
          this.currentLabelerInfo =
            this.FilteredAnnotationAnswers[0].labeler_details;
          this.currentDatasetInfo =
            _this.arrProjectImages[0].dataset_details;
          this.currentImageInfo = {
            id: _this.arrProjectImages[0].id,
            path: _this.arrProjectImages[0].image_file,
          };
        }
      }, this.dataLoadingTimeout);
    } else {
      this.utility.DisplayMessage(
        Enums.Message_No_Image_For_Work,
        Enums.Message_Type_Sucess
      );
    }
  }

  /**
   * This will return true if Polygon drwaing is in process.
   * If not then activated then it will return false
   */
  isDrwaingActivatedForPolygons(){
    let drawingActivated = d3.selectAll('.drawPoly').empty();
    return drawingActivated;
  }

  StartAnnotation() {
    let currentImageURL;
    if (this.arrProjectImages.length > 0) {
      if (this.arrCurrentAnnotatedImageIndex >= this.arrProjectImages.length) {
        alert('yupee all images annotated');
      } else {
        
        currentImageURL = this.arrProjectImages[0].image_file;
        this.arrCurrentAnnotatedImageIndex = 0;

        let _this = this; 
        const img = new Image();

        /**
         * After API requestes, the main loading spinner keeps on
         * loading until unless the image is not completely loaded.
         */
        let loding_spinner = $('.spinner-overlay');
        loding_spinner.addClass('show-spinner');

        img.onload = function () {
          $('#containerAnnotation').css(
            'background-image',
            `url('${currentImageURL}')`
          );
          $('#containerAnnotation').css('width', `${img.width}px`);
          $('#containerAnnotation').css('height', `${img.height}px`);
          $('#containerAnnotation').css('overflow', `hidden`);

          _this.currentImageWidth = img.width;
          _this.currentImageHeight = img.height;

          _this.currentImagePointsFactor =
            _this.currentImageWidth * _this.perPixelFactor;

          //Checking if the role is Labler then start initiating the annotation process
          if (_this.route.snapshot.params['action'] == Enums.Role_Labeler){
            $('#containerAnnotation').empty();
            $('.tool-icon').removeClass('selected');

            /**
             * Remove spinner as image is loaded now for labeler now
             */
            setTimeout( function(){
              loding_spinner.removeClass('show-spinner');
            }, 500 );

          }
          _this.defaulttool();
        };
        img.src = currentImageURL;
      }
    }
  }

  maxcount(){

    // Getting entered max counts into variables
     this.projectService
     .getProjectDetails(this.route.snapshot.params['id'])
     .subscribe(
       (response: any) => {
        {
          this.maxcountlabeler = response.max_count_labeler;
          this.maxcountreviewer = response.max_count_reviewer;
         } 
        })
  }
  SubmitData(mode: any) {

    let currentUserRole = JSON.parse(localStorage.getItem('user')).user
      .user_type;
    /**
     * Do not submit any annotation if there is not polygon drawn
     */
    if( $('svg').children().length == 0 || this.arrAllPolygons == 0 ) {
      this.utility.DisplayException(
        Enums.Message_Polygons_draw,
        Enums.Message_Failure,
      );
      return false
    }

    /**
    * Do not submit image more than max count
    */
   this.GetProjectStats();
    if(currentUserRole == 2 && this.countone == this.maxcountlabeler || this.countone > this.maxcountlabeler){
      this.utility.DisplayMessage(
                   Enums.Message_No_Image_For_Work,
                   Enums.Message_Type_Sucess
                 );
           return false;
    }
    if(currentUserRole == 3 && this.counttwo == this.maxcountreviewer || this.counttwo > this.maxcountreviewer){
      this.utility.DisplayMessage(
                   Enums.Message_No_Image_For_Work,
                   Enums.Message_Type_Sucess
                 );
           return false;
    }
    
    console.log("SubmitData");
    let arrOptionsData = [];
    let projectId = this.route.snapshot.params['id'];
    $('.classifier-options').each((i, item) => {
      if ($(item).is(':checked')) {
        var objOptionData: any = {};
        objOptionData.answer = $(item).val();
        objOptionData.classifier = $(item).attr('data-classifier-id');
        objOptionData.classifier_option = $(item).attr(
          'data-classifier-option-id'
        );
        objOptionData.data_set_image =
          this.arrProjectImages[this.arrCurrentAnnotatedImageIndex].id;
        if (this.route.snapshot.params['action'] == Enums.Role_Labeler) {
          if (mode == Enums.Image_Status_Skipped) {
            objOptionData.status = Enums.Image_Status_Skipped;
            objOptionData.skipped_by = this.user.user.id;
          } else {
            objOptionData.status = Enums.Image_Status_Labeled;
            objOptionData.labeled_by = this.user.user.id;
          }
        } else {
          if (mode == Enums.Image_Status_Rejected) {
            objOptionData.status = Enums.Image_Status_Rejected;
            objOptionData.rejected_by = this.user.user.id;
          }
          else if(mode == Enums.Image_Status_Disapproved){
            objOptionData.status = Enums.Image_Status_Disapproved;
            objOptionData.disapproved_by = this.user.user.id;
            objOptionData.disapproved_for = this.currentLabelerInfo.id;
          } else {
            objOptionData.status = Enums.Image_Status_Approved;
            objOptionData.approved_by = this.user.user.id;
            objOptionData.disapproved_for = this.currentLabelerInfo.id;
          }
        }

        arrOptionsData.push(objOptionData);
      }
    });

    this.annotationService
      .SubmitProjectClassifiers(
        arrOptionsData,
        projectId,
        arrOptionsData[0].data_set_image
      )
      .subscribe(
        (response: any) => {
          //if (response != null) {
          this.SubmitImageAnnotation(mode);
          this.clearLocalStorageData('current_annotation_data');
          this.GetProjectStats();
          //}
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  SubmitImageAnnotation(mode:any) {
    let objAnnotation: any = {};
    let currentImageId = 0;
    let actionPerformedFor = 0;
    if (this.arrProjectImages.length > 0) {
      currentImageId = this.arrProjectImages[0].annotation_answer[0].id;
    }
    let arrSegments = [];
    $('svg')
      .children()
      .each((i, item) => {
        let arrPol = $(item).find('polygon');
        $(arrPol.length > 0);
        {
          let points = $(arrPol[0]).attr('points');
          if (points != '') {
            console.log(points);
            let arrNewPoints = [];
            let unSplittedPoints = points.split(',');
            for (var index = 0; index < unSplittedPoints.length; index++) {
              if (index % 2 == 0) {
                arrNewPoints.push(Number(unSplittedPoints[index]));
              } else {
                arrNewPoints.push(Number(unSplittedPoints[index]));
              }
            }
            arrSegments.push(arrNewPoints.toString());
          }
        }
      });
    //return;
    objAnnotation.raw_annotation_data = JSON.stringify(arrSegments);
    objAnnotation.project = this.route.snapshot.params['id'];
    objAnnotation.x_mapping_factor = this.currentImagePointsFactor;
    objAnnotation.y_mapping_factor = this.currentImagePointsFactor;
    objAnnotation.data_set_image =
      this.arrProjectImages[this.arrCurrentAnnotatedImageIndex].id;
    objAnnotation.is_locked = false;

    if (this.route.snapshot.params['action'] == Enums.Role_Labeler) {
      if (mode == Enums.Image_Status_Skipped) {
        objAnnotation.status = Enums.Image_Status_Skipped;
        objAnnotation.skipped_by = this.user.user.id;
      } else {
        objAnnotation.status = Enums.Image_Status_Labeled;
        objAnnotation.labeled_by = this.user.user.id;
      }
    } else {
      if (mode == Enums.Image_Status_Rejected) {
        objAnnotation.status = Enums.Image_Status_Rejected;
        objAnnotation.rejected_by = this.user.user.id;
      } 
      /**
       * Disapproving image means it is not well labeled, hence
       * it not approved and make it available for next pool
       */
      else if(mode == Enums.Image_Status_Disapproved){
        objAnnotation.status = Enums.Image_Status_Disapproved;
        objAnnotation.disapproved_by = this.user.user.id;
        objAnnotation.raw_annotation_data = '[]';
        objAnnotation.x_mapping_factor = 0;
        objAnnotation.y_mapping_factor = 0;
        objAnnotation.disapproved_for = this.currentLabelerInfo.id;
        actionPerformedFor = this.currentLabelerInfo.id;
      } else {
        objAnnotation.status = Enums.Image_Status_Approved;
        objAnnotation.approved_by = this.user.user.id;
        objAnnotation.disapproved_for = this.currentLabelerInfo.id;
        actionPerformedFor = this.currentLabelerInfo.id;
      }
    }
    console.log("SubmitImageAnnotation");
    this.annotationService
      .SubmitProjectAnnotation(objAnnotation, currentImageId)
      .subscribe(
        (response: any) => {
          if (response != null) {
            $('.classifier-options').prop('checked', false);
            
            if (this.route.snapshot.params['action'] == Enums.Role_Labeler) {

              //clear previous image before rendering new one
              this.clearPreviousImage();
            }

            if( mode == Enums.Image_Status_Disapproved )
              this.GetProjectImages(mode);
            else
              this.GetProjectImages('');
            
            this.GetProjectClassfiers();

            //Saving current image stats
            this.SubmitAnnotationStats(
              objAnnotation.status, 
              this.arrProjectImages[this.arrCurrentAnnotatedImageIndex].id,
              this.route.snapshot.params['id'], 
              this.user.user.id,
              actionPerformedFor
            );

            //clear old polygons points before rendering new points  
            this.clearPolygons();

            //Save active time spent on labeling/reviewing an image
            this.SubmitActiveTimeLog(
              objAnnotation.status, 
              this.arrProjectImages[this.arrCurrentAnnotatedImageIndex].id,
              this.route.snapshot.params['id'], 
              this.user.user.id,
              this.timeSpentOnPage
            );
            
            //Reset time when its submitted
            this.TrackTime('reset');
            
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  SubmitActiveTimeLog(
    mode:any, 
    data_set_image_id:Number,
    project_id:Number,
    user_id:Number, 
    data:any
    ){

    this.annotationService.SubmitActiveTimeLogService(mode, data_set_image_id, project_id, user_id, data)
      .subscribe(
        (response: any) => {
          if (response != null) {
            console.log(response);
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_ActiveTime,
            Enums.Message_Type_ActiveTime
          );
        }
      );
  }

  SubmitAnnotationStats(
    mode:any, 
    data_set_image_id:Number,
    project_id:Number,
    perform_by:Number, 
    perform_for:Number
    ){

    this.annotationService.SubmitAnnotationStatsService(mode, data_set_image_id, project_id, perform_by, perform_for)
      .subscribe(
        (response: any) => {
          if (response != null) {
            console.log(response);
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_AnnotationStats,
            Enums.Message_Type_AnnotationStats
          );
        }
      );
  }

  //Start D3 Implementation
  dragging: any = false;
  drawing: any = false;
  startPoint: any;
  // svg: any;
  points: any = [];
  arrAllPolygons: any = [];
  g: any;
  dragger: any;

  polygonPointsArray: any = [];
  gArray: any = [];

  StartD3() {
    this.initializeD3();
  }
  
  initializeD3(draw?: any) {
    let _this = this;
    // getting the radius of the vertex from radiussize function.
    _this.radiussize();

    if ( _this.svg != null ) {
      if( draw == "" ){
        _this.svg.selectAll('*').remove();
        $($('#containerAnnotation svg')[0]).remove();
        _this.arrAllPolygons = [];
      }
    }

    let viewBox : any;
    if ( $('#containerAnnotation svg').length == 0 ){
      viewBox = '0 0 '+_this.currentImageWidth+' '+_this.currentImageHeight;
      _this.svg = d3
        .select('#containerAnnotation')
        .append('svg')
        .attr('height', _this.currentImageHeight)
        .attr('width', _this.currentImageWidth)
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', viewBox)
        .classed('annotation-container', true);
    }
    

    _this.dragger = d3
      .drag()
      .on('drag', function () {
        //if (_this.drawing) return;
        var dragCircle = d3.select(this),
          newPoints = [],
          circle;
        _this.dragging = true;
        _this.TrackTime("start");
        let parentNode = this.parentNode; //event.target['parentNode'];

        var poly = d3.select(parentNode).select('polygon');
        var circles = d3.select(parentNode).selectAll('circle');
        
        let currentCircleIndex = Array.from(
          this.parentElement.querySelectorAll('circle')
        ).indexOf(this);
        let currentGid = this.parentElement.id;

        dragCircle.attr('cx', d3.event.x).attr('cy', d3.event.y);
        for (var i = 0; i < circles._groups[0].length; i++) {
          circle = d3.select(circles._groups[0][i]);
          newPoints.push([circle.attr('cx'), circle.attr('cy')]);
        }
        poly.attr('points', newPoints);

        /**
         * This will make the exact array of current
         * Corrdinates of dragging vertex and update the points
         */
        _this.gArray[currentGid][currentCircleIndex] = [
            d3.event.x, d3.event.y
        ];
        

      });

    _this.svg.on('mouseup', function () {
      let _event: any = event;
      
      /**
       * If this condition is true from partiuclar function
       * then the polygon drwaing won't be available
       */
      console.log(_this.disablePolygonDrawing);
      if( _this.disablePolygonDrawing ) return;
      
      /**
       * Polygon dragging won't be available if drawing is in process
       */
      d3.selectAll('.dragging').classed('disable', true);

      if (_this.dragging) return;

      // Right Click
      if (_event.which == 3) {
        _this.InitContextMenu(this);
        return;
      }
      console.log('mouseup event');
      _this.drawing = true;

      _this.TrackTime("start");

      _this.startPoint = [d3.mouse(this)[0], d3.mouse(this)[1]];
      if (_this.svg.select('g.drawPoly').empty()) {
        _this.g = _this.svg.append('g')
          // .attr('pointer-events', 'none')
          .attr('class', 'drawPoly');
      }

      if (d3.event.target.hasAttribute('is-handle')) {
        _this.closePolygon();
        _this.TrackTime("stop");
        return;
      }
      _this.points.push(d3.mouse(this));

      _this.g.select('polyline').remove();
      var polyline = _this.g
        .append('polyline')
        .attr('points', _this.points)
        .style('fill', 'none')
        .attr('stroke', '#fff');

      for (var i = 0; i < _this.points.length; i++) {
        _this.g
          .append('circle')
          .attr('cx', _this.points[i][0])
          .attr('cy', _this.points[i][1])
          .attr('r', _this.radius)
          .attr('fill', 'red')
          .attr('stroke', '#000')
          .attr('is-handle', 'true')
          .style({ cursor: 'pointer' });
      }
      
      _this.polygonPointsArray.push([d3.mouse(this)[0], d3.mouse(this)[1]]);

    });
    _this.svg.on('mousemove', function () {
      if( typeof _this.startPoint !== 'undefined' ){
        if (!_this.drawing) return;

        _this.TrackTime("start");

        var g = d3.select('g.drawPoly');
        
        g.select('line').remove();
        var line = g
          .append('line')
          .attr('x1', _this.startPoint[0])
          .attr('y1', _this.startPoint[1])
          .attr('x2', d3.mouse(this)[0] + 2)
          .attr('y2', d3.mouse(this)[1])
          .attr('stroke', '#53DBF3')
          .attr('stroke-width', 1);
      }

    });
    
  }

  clearPolygons(){
    $('svg').children().remove();
  }

  clearPreviousImage(){
    $('#containerAnnotation').removeAttr('style');
    //$('.spinner-overlay').attr("hidden");
  }

  mutationCallbackEvent = function( mutationsList, observer ){
    //$('.spinner-overlay').attr("hidden");
    var annoation_bg_image_url = mutationsList[0].target.style.backgroundImage.slice(4, -1).replace(/"/g, "");
    if( annoation_bg_image_url !== "" ){
      //$('.spinner-overlay').removeAttr("hidden");
    }
    observer.disconnect();
    for( var mutation of mutationsList ) {
        // var annoation_bg_image_url = mutation.target.style.backgroundImage.slice(4, -1).replace(/"/g, "");
        
        // if( annoation_bg_image_url == "" ){
        //   //alert("a");
        //   //$('.spinner-overlay').show();
        //   //console.log(annoation_bg_image_url);
        // }
        // else{
        //   //alert("b");
        //   //$('.spinner-overlay').hide();
        // }

        if ( mutation.type == 'childList' ) {
            Array.prototype.forEach.call( mutation.target.children, function ( child ) {
                if ( child.tagName === "IMG" ) {
                    
                }
            } );
        }
    }
  }

  closePolygon() {
    let _this = this;
    let uuid = _this.utility.IDGenerator();
    let gId = _this.utility.getRandomString(6);
    gId = 'g-'+gId;

    // getting the radius of the vertex from radiussize function.
    _this.radiussize();

    let randomColor = _this.getRandomColor();
    _this.svg.select('g.drawPoly').remove();
    var g = _this.svg.append('g');
    g.attr('class', '')
     .attr('id', gId);

    g.append('polygon')
      .attr('points', _this.points)
      .attr('uuid', uuid)
      .style('fill', randomColor)
      .style('opacity', '0.5');
    for (var i = 0; i < _this.points.length; i++) {
      var circle = g.append('circle')
      .attr("cx", _this.points[i][0])
      .attr("cy", _this.points[i][1])
      .attr("r", _this.radius)
      .call(_this.dragger);
      // var circle = g
      //   .selectAll('circles')
      //   .data([_this.points[i]])
      //   .enter()
      //   .append('circle')
      //   .attr('cx', _this.points[i][0])
      //   .attr('cy', _this.points[i][1])
      //   .attr('r', 4)
      //   .attr('fill', '#FDBC07')
      //   .attr('stroke', '#000')
      //   .attr('is-handle', 'true')
      //   .style({ cursor: 'move' })
      //   .call(_this.dragger)
      //   .on('click', function () {
      //     //$(this).remove();
      //   });
    }

    /**
     * Code below is main code which will enable dragging on a 
     * complete Polygon
     */
      g.datum({
          x: 0,
          y: 0
      })

      g.attr("transform", function(d) {
        return "translate(" + d.x + "," + d.y + ")"
      });

      g.call( 
        d3.drag()
        .on('start', function(d) {
          console.log('started');
        })
        .on("drag", function(d) {
        /**
         * Will enable dragging only if the CTRL is pressed
         */
        if( this.classList.contains("make-draggable") ){
          
          /**
           * Getting the current dragging polygon id as index
           * to drag and set the points of exact polygon
           */
          let currentDraggerGId = g.attr('id');
          let currentPolygonVertexPoints = _this.gArray[currentDraggerGId];

          var e = d3.event;
          currentPolygonVertexPoints.forEach( function(datum, index){
              let datum_x = ( _this.currentRole == Enums.Role_Approver ) ? parseFloat(datum[0]) : datum[0];
              let datum_y = ( _this.currentRole == Enums.Role_Approver ) ? parseFloat(datum[1]) : datum[1];
              datum[0] = parseFloat( datum[0] ) + parseFloat( e.dx );
              datum[1] = parseFloat( datum[1] ) + parseFloat( e.dy );
          });
        
          
          
          let poly = d3.select(this).select('polygon');
          poly = ( _this.currentRole == Enums.Role_Approver ) ?
            $(poly._groups[0][0]) : poly;  
          
          poly.attr('points', currentPolygonVertexPoints);

          /**
           * Getting the current dragging vertex of selected Polygon  
           * to drag and set the points of exact vertex
           */
          let newcircle = d3.select('#'+currentDraggerGId).selectAll('circle').data(currentPolygonVertexPoints);
          newcircle.attr('cx', function(d) {
              return d[0]; 
            })
          .attr('cy', function(d) { 
            return d[1]; 
          });    
        }
        })
        .on('end', function(d) {
          console.log('ended');
        })
      );

    /**
     * This will push the Polygon points to the DOM
     */
    _this.PostOperationClosePloygon(g, randomColor, uuid, _this.points);
    _this.points.splice(0);

    /**
     * Getting polygin unique ID
     */
    let currentGid = g._groups[0][0].id;
    /**
     * Assinging all vertex points array to a single polygon 
     * when a cycle completed for drawing a single polygon
     */
    _this.gArray[currentGid] = _this.polygonPointsArray;
    /**
     * Resting the Polygon points array when a cycle completed for
     * drawing a single polygon
     */
    _this.polygonPointsArray = [];

    /**
     * Selecting all vertexes and making them hidden by adding class
     * to all vertexes
     */
    let allVertexes = d3.selectAll('circle');
    allVertexes.classed('hide-vertex', true);

    /**
     * Make Polygon dragging be available if drawing is completed
      */
    d3.selectAll('.dragging').classed('disable', false);

    g.classed('un-clickable', true);

  }

  /**
   * Callback function of enable the dragging process and push points 
   * to the polygon attribute
   * @returns 
   */
  handleDrag() {
    let _this = this;

    if (_this.drawing) return;
    var dragCircle = d3.select(this),
      newPoints = [],
      circle;
    _this.dragging = true;
    let parentNode = event.target['parentNode'];

    var poly = d3.select(parentNode).select('polygon');
    var circles = d3.select(parentNode).selectAll('circle');

    dragCircle.attr('cx', d3.event.x).attr('cy', d3.event.y);
    for (var i = 0; i < circles[0].length; i++) {
      circle = d3.select(circles[0][i]);
      newPoints.push([circle.attr('cx'), circle.attr('cy')]);
    }
    poly.attr('points', newPoints);
  }
  getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  undoPoints() {
    
    let _this = this;
    /**
     * Removing last node of vertex
     */
    let arrLastPoints: any = this.points.pop();

    /**
     * Removing last node of points perpared for dragging array
     */
    this.polygonPointsArray.pop();
  
      // var evt = document.createEvent("MouseEvents");
      // evt.initEvent("mouseup", true, true);
      // this.svg[0][0].dispatchEvent(evt);
      let currentG = this.g._groups[0][0];
      let strPoints = $(currentG).find('polyline').attr('points');
      let countstrPoints = strPoints.split(',');

      let finalizeStr = strPoints.replace(
        ',' + arrLastPoints.toString().replace(/ /g, ''),
        ''
      );
      
      $(currentG).find('polyline').attr('points', finalizeStr);

      
      //$(currentG).find(`circle[cx=${arrLastPoints[0]}][cy=${arrLastPoints[1]}]`).remove();

      if (arrLastPoints[0].toString().indexOf('.') > -1) {
        $(currentG)
          .find('circle[cy="' + arrLastPoints[1] + '"]')
          .remove();
        // $(currentG).find(`circle[cy=${arrLastPoints[1]}]`).remove();
      } else {
        $(currentG)
          .find('circle[cx="' + arrLastPoints[0] + '"]')
          .remove();
        // $(currentG).find(`circle[cx=${arrLastPoints[0]}]`).remove();
      }
      let lstCX = $('.drawPoly').find('circle:last').attr('cx');
      let lstCY = $('.drawPoly').find('circle:last').attr('cy');
      $(currentG).find(`line`).attr('x1', lstCX);
      $(currentG).find(`line`).attr('y1', lstCY);
      this.startPoint[0] = lstCX;
      this.startPoint[1] = lstCY;

      if( countstrPoints.length <= 2 ){
        /**
         * Make Polygon dragging be available if last vertex is deleted
         */
        d3.selectAll('.dragging').classed('disable', false);
        this.g.remove();
      }
      

    //this.drawing = false;
  }
  ShowPoints() {
    console.log(this.points);
  }
  DeleteSegment(ref: any, currentSegment: any) {
    $(currentSegment).remove();
  }
  PostOperationClosePloygon(ref: any, color: any, uuid: any, points: any) {
    let objPolygon: any = {};
    objPolygon.Control = ref;
    objPolygon.UUID = uuid;
    objPolygon.Color = color;
    objPolygon.Points = JSON.stringify(points);
    this.arrAllPolygons.push(objPolygon);
  }
  ChangePloygonVisibility(index: any) {
    $(this.arrAllPolygons[index].Control).toggleClass('hidden');
  }
  //End D3 Implementation

  // Handle Events

  InitContextMenu(ref: any) {
    let _this = this;
    $(ref).contextMenu({
      selector: 'g',
      events: {
        show: function (options) {
          _this.TrackTime("start");
          console.log(options);
        },
        hide: function (options) {
          _this.svg.select('.context-menu-active')
            .style('opacity', '1')
            .style('stroke', 'transparent')
            .style('stroke-width', '0');

            _this.TrackTime("stop");
        },
        activated: function (options) {
          
          setTimeout(function(){
            _this.svg.select('.context-menu-active')
              .style('opacity', '0.7')
              .style('stroke', '#000')
              .style('stroke-width', '5')
              .style('stroke-dasharray', '10');
          },100);
        },
      },
      callback: function (key, options) {
        if (key.toLowerCase() == Enums.Segment_Operation_Delete.toLowerCase()) {
          _this.DeleteSegment(ref, this);
        }

        var m = 'clicked: ' + key + ' on ' + $(this).text();
      },
      items: {
        BTF: { name: Enums.Segment_Operation_BTF, icon: 'fa-forward disabled' },
        sep1: '---------',
        BTB: {
          name: Enums.Segment_Operation_BTB,
          icon: 'fa-backward disabled',
        },
        sep2: '---------',
        Copy: { name: Enums.Segment_Operation_Copy, icon: 'fa-clone disabled' },
        sep3: '---------',
        Paste: {
          name: Enums.Segment_Operation_Paste,
          icon: 'fa-clipboard disabled',
        },
        sep4: '---------',
        Delete: { name: Enums.Segment_Operation_Delete, icon: 'fa-trash' },
      },
    });
  }

  /**
   * This function will collect the points from DOM and the
   * populate those points for dragging behviour (for already drawn Polygons) 
   * when user is logged in as Approver.
   */
  prePoplulateDragingPointsforReviewer(){
    let _this = this;
    let selectPolyelements = document.querySelector("svg").querySelectorAll('g');
    if( selectPolyelements.length > 0 ){
      selectPolyelements.forEach( function(ele, index){
        _this.polygonPointsArray = [];
        let selectPolygonId = ele.id;
        let allVertexes = ele.querySelectorAll('circle');
        let allPolygons = ele.querySelectorAll('polygon');
        allVertexes.forEach(function(e, i){
          let cx = e.getAttribute('cx');
          let cy = e.getAttribute('cy');
          _this.polygonPointsArray.push([cx, cy]);
        });
        _this.gArray[selectPolygonId] = _this.polygonPointsArray;
      });
    }
  }

  DrawPrePopulatePolygons(strPoints) {
    /**
     * After API requestes, the main loading spinner keeps on
     * loading until unless the image is not completely loaded.
     */
    let loding_spinner = $('.spinner-overlay');

    let _this = this;
    //   this.svg.append("polygon")
    //  .attr("points", "200,10 250,190 160,210")
    //  .style("fill", "green")
    //  .style("stroke", "black")
    //  .style("strokeWidth", "10px");

// getting the radius of the vertex from radiussize function.
    _this.radiussize();

    //let strPoints  = `["466.70001220703125,223,469.70001220703125,188,486.70001220703125,142,501.70001220703125,114,533.7000122070312,84,550.7000122070312,78,598.7000122070312,71,633.7000122070312,67,678.7000122070312,90,713.7000122070312,109,742.7000122070312,149,745.7000122070312,161,758.7000122070312,185,772.7000122070312,194,774.7000122070312,178,771.7000122070312,157,769.7000122070312,140,768.7000122070312,124,743.7000122070312,90,725.7000122070312,80,667.7000122070312,56,631.7000122070312,45,583.7000122070312,40,551.7000122070312,43,514.7000122070312,56,474.70001220703125,81,462.70001220703125,117,453.70001220703125,157,443.70001220703125,190,440.70001220703125,211,438.70001220703125,228,442.70001220703125,234,462.70001220703125,223"]`;
    let points = strPoints.split(',');
    
    //If browser displays SVG as undefined due to loading process, then reinitiate SVG
    if( typeof this.svg === 'undefined' )
      this.initializeD3();

    _this.g = _this.svg.append('g').attr('class', 'drawPoly');
    _this.g.select('polyline').remove();

    var polyline = _this.g
      .append('polyline')
      .attr('points', points)
      .style('fill', 'none')
      .attr('stroke', '#fff');
    
    for (var i = 0; i < points.length; i = i + 2) {
      _this.g
        .append('circle')
        .attr('cx', points[i])
        .attr('cy', points[i + 1])
        .attr('r', _this.radius)
        .attr('fill', 'red')
        .attr('stroke', '#000')
        .attr('is-handle', 'true')
        // .call(_this.dragger);
        .style({ cursor: 'pointer' });

      _this.points.push([points[i], points[i + 1]]);
    }

    _this.svg.attr('viewBox', '0 0 '+_this.currentImageWidth+' '+_this.currentImageHeight );
    _this.svg.attr('width', _this.currentImageWidth );
    _this.svg.attr('height', _this.currentImageHeight );

    /**
     * Remove spinner as polygons are loaded now
     */
     setTimeout( function(){
      loding_spinner.removeClass('show-spinner');
    }, 400 );

    _this.prePoplulateDragingPointsforReviewer();
    _this.closePolygon();

    return;
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.UnLockCurrentImage('');
  }
  @HostListener('window:hashchange', ['$event'])
  onHashChange(event) {
    this.UnLockCurrentImage('');
  }

  UnLockCurrentImage(isFrom) {
    let objAnnotation: any = {};
    let currentImageId = 0;
    if (this.arrProjectImages.length > 0) {
      currentImageId = this.arrProjectImages[0].annotation_answer[0].id;
    }

    objAnnotation.project = this.route.snapshot.params['id'];
    objAnnotation.is_locked = false;
    objAnnotation.data_set_image =
      this.arrProjectImages[this.arrCurrentAnnotatedImageIndex].id;

    this.annotationService
      .SubmitProjectAnnotation(objAnnotation, currentImageId)
      .subscribe(
        (response: any) => {
          if (response != null) {
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }

  clearLocalStorageData(key: any){
    localStorage.removeItem(key);
  }

  SkipImage() {
    this.SubmitData(Enums.Image_Status_Skipped);
    this.clearLocalStorageData('current_annotation_data');
    this.TrackTime('reset');
  }

  RejectImage() {
    this.SubmitData(Enums.Image_Status_Rejected);
    this.clearLocalStorageData('current_annotation_data');
    this.TrackTime('reset');
  }

  DisapproveAnnotation() {
    this.SubmitData(Enums.Image_Status_Disapproved);
    this.clearLocalStorageData('current_annotation_data');
    this.TrackTime('reset');
  }

  TrackTime(mode:any){

    let ActiveTime = TimeMe;

    ActiveTime.initialize({
      idleTimeoutInSeconds: 30 // seconds
    });

    ActiveTime.callAfterTimeElapsedInSeconds(4, function(){
      //console.log("The user has been using the page for 4 seconds! Let's prompt them with something.");
    });
  
    ActiveTime.callAfterTimeElapsedInSeconds(9, function(){
      //console.log("The user has been using the page for 9 seconds! Let's prompt them with something.");
    });

    if( mode == "start" ){

      this.timeSpentOnPage = ActiveTime.getTimeOnCurrentPageInSeconds();

      window.onload = function(){
        ActiveTime.trackTimeOnElement('containerAnnotation svg g');
        // setInterval(function(){
        //   var timeSpentOnElement = TimeMe.getTimeOnElementInSeconds('containerAnnotation');
        //   this.timeSpentOnPage = timeSpentOnElement.toFixed(2);

        // }, 25);
      }
    }

    if( mode == "stop" ){ ActiveTime.stopTimer(); }

    if( mode == "reset" ){ ActiveTime.resetAllRecordedPageTimes(); this.timeSpentOnPage = 0; }
    
  }

  // End Handle Events

  GetProjectStats() {
    let currentUser = JSON.parse(localStorage.user).user;
    this.projectService
      .getProjectStats(this.route.snapshot.params['id'])
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.projectStats = response;

            if (this.currentRole == Enums.Role_Labeler) {
              this.currentLabelerUserStats =
                this.projectStats.labeler_data.filter((obj) => {
                  if (currentUser.email == obj.email)
                  this.countone = obj.count;
                  this.countone+1;
                    return obj;
                });

              let currentSkippedUserStats =
                this.projectStats.skipped_data.filter((obj) => {
                  if (currentUser.email == obj.email)
                    return obj;
                });

              let currentDisapproverUserStats =
                this.projectStats.disapproved_data.filter((obj) => {
                  //if ( this.currentLabelerUserStats.length > 0 ){
                    if( currentUser.email == obj.email )
                      return obj;
                  //}
                    
                });

                console.log(this.currentLabelerUserStats);

            } else if (this.currentRole == Enums.Role_Approver) {
              this.currentApproverUserStats =
                this.projectStats.approver_data.filter((obj) => {
                  if (currentUser.email == obj.email)
                    this.counttwo = obj.count;
                    this.counttwo+1;
                    return obj;
                });

              let currentRejectedUserStats =
                this.projectStats.rejected_data.filter((obj) => {
                  if (currentUser.email == obj.email)
                    return obj;
                });
            }
          }
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
  }
}
