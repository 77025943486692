<div class="ugf-main-wrap ugf-bg">
  <div class="ugf-header">
    <div class="logo">
      <a href="#"
        ><img
          src="../../assets/images/logo-full.svg"
          class="img-fluid"
          alt="Algoface Annotation Tool"
      /></a>
    </div>
    <div class="alternet-access">
      <p>Already have an account? <a routerLink="/login">Log in now!</a></p>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col">
        <div class="ugf-container pt200">
          <div class="content-wrap">
            <div class="ugf-form-block-header">
              <h1>Register Your Account</h1>
              <p>
                Enter your valid email address and password <br />
                to register your account
              </p>
            </div>
            <div class="ugf-input-block">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <input
                    type="text"
                    formControlName="username"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                    placeholder="Enter Email"
                  />
                  <div
                    *ngIf="submitted && f.username.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.username.errors.required">
                      Username is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    formControlName="name"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                    placeholder="Enter Full Name"
                  />
                  <div
                    *ngIf="submitted && f.name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.name.errors.required">name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    formControlName="password"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    placeholder="Enter Password"
                  />
                  <div
                    *ngIf="submitted && f.password.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.password.errors.required">
                      Password is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <select
                    name="roles"
                    id="roles"
                    class="form-control form-value"
                    formControlName="role"
                    [ngClass]="{ 'is-invalid': submitted && f.role.errors }"
                  >
                    <option value="">--Select Role--</option>
                    <option value="2">Labeler</option>
                    <option value="3">Reviewer</option>
                  </select>
                  <div
                    *ngIf="submitted && f.role.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.role.errors.required">Role is required</div>
                  </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                  <span
                    *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"
                  ></span>
                  Register Account
                </button>
                <p class="terms">
                  By clicking here and continuing, <br />
                  I agree to the <a href="#">Terms of Service</a> and
                  <a href="#">Privacy Policy</a>.
                </p>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
                  {{ error }}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
