import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';

import { environment } from '@environments/environment';

import { Observable } from 'rxjs';
import { Enums } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(private http: HttpClient) {}

  createProject(objData: any) {
    return this.http.post(`${environment.apiUrl}/project/`, objData);
  }
  getAllProjects(offset: Number, limit: Number) {
    return this.http.get(
      `${environment.apiUrl}/project/?limit=${limit}&offset=${offset}`
    );
  }
  getProjectDetails(projectId: Number) {
    return this.http.get(
      `${environment.apiUrl}/project/${projectId}/?limit=10&offset=0`
    );
  }
  getProjectImages(image_type: String, project_id: Number) {
    let queryString = '';
    if (image_type.toLowerCase() == Enums.Image_Type_Pending.toLowerCase()) {
      queryString = `?i_type=${image_type}&`;
    } else if (
      image_type.toLowerCase() == Enums.Image_Type_Labeled.toLowerCase()
    ) {
      queryString = `?i_type=${image_type}&`;
    } else if (
      image_type.toLowerCase() == Enums.Image_Type_Disapproved.toLowerCase()
    ) {
      queryString = `?i_type=${image_type}&`;
    }else {
      queryString = '?';
    }
    return this.http.get(
      `${environment.apiUrl}/project/${project_id}/images/${queryString}limit=12&offset=0`
    );
  }
  getDatasetImages(dataset_id: Number, offset: Number, limit: Number) {
    return this.http.get(
      `${environment.apiUrl}/datasetimage/?data_set=${dataset_id}&limit=${limit}&offset=${offset}`
    );
  }
  detachDataset(dataset_id: Number, project_id: Number) {
    return this.http.post(
      `${environment.apiUrl}/project/${project_id}/delete-dataset/`,
      { data_set: dataset_id }
    );
  }
  getAllNonAddedUserOfProject(project_id: Number) {
    return this.http.get(
      `${environment.apiUrl}/project/${project_id}/nadded-users/`
    );
  }
  getAllRoles() {
    return this.http.get(`${environment.apiUrl}/role/`);
  }
  getProjectUsers(project_id: Number) {
    return this.http.get(
      `${environment.apiUrl}/project-role/?project=${project_id}`
    );
  }
  AddUserToProject(project_id: Number, user_id: Number, role_id: Number) {
    return this.http.post(`${environment.apiUrl}/project-role/`, {
      project: project_id,
      user: user_id,
      role: role_id,
    });
  }
  SetMaxCountToProject(projectid: Number, labelercount: Number, approvercount: Number){
    return this.http.put(`${environment.apiUrl}/project/${projectid}/set-max-count/`,
      {
        max_count_labeler: labelercount,
        max_count_reviewer:  approvercount,
    });
  }

  DeleteUserFromProject(project_id: Number, user_id: Number, role_id: Number, status: any, group_id : Number) {
    return this.http.put(`${environment.apiUrl}/delete-project-user/${group_id}/`, {
      project: project_id,
      user: user_id,
      role: role_id,
      is_active: status
    });
  }
  getProjectStats(project_id: Number) {
    return this.http.get(`${environment.apiUrl}/project/${project_id}/stats/`);
  }
  getActiveTimeStats(project_id: Number, user_id: Number) {
    return this.http.get(`${environment.apiUrl}/annotation_activetime_log_stats/?project=${project_id}&user=${user_id}`);
  }
  
  getCurrentProjectStats(project_id: Number, user_id: Number) {
    return this.http.get(`${environment.apiUrl}/annotation_get_stats/?project=${project_id}&user=${user_id}`);
  }
  
  exportProject(project_id: Number) {    
    return this.http.get(
      `${environment.apiUrl}/project/${project_id}/export/`
    );
  }
}
