import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatasetService, ProjectService } from '@app/_services';
import { Utility } from '@app/_helpers';
import { Enums } from '@app/_models';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
@Component({ templateUrl: 'project-new.html' })
export class ProjectNewComponent implements OnInit {
  loading = false;
  projectForm: FormGroup;
  submitted = false;
  arrAllDatasets: any;
  Page_Number: number = 1;
  Page_Size: number = Enums.Page_Size;
  public Search_Query: any = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private datasetService: DatasetService,
    private utility: Utility,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [],
      data_set: [],
    });
    eval('ReadyEvent()');
    this.GetAllDatasets();
  }
  onSubmit() {
    if (this.projectForm.value.name === '') {
      this.utility.DisplayMessage(
        Enums.Filename_Failure,
        Enums.Message_Type_Error
      );
      return;
    }

    if (
      this.projectForm.status.toLowerCase() ==
      Enums.Form_Status_Valid.toLowerCase()
    ) {
      let arrDatasetIds = [];
      $('.active-row').each((i, item) => {
        arrDatasetIds.push($(item).attr('id'));
      });
      this.projectForm.value.data_set = arrDatasetIds;
      if (this.projectForm.value.data_set.length === 0) {
        this.utility.DisplayMessage(
          Enums.Attach_Dataset_Failure,
          Enums.Message_Type_Error
        );
        return;
      }

      this.projectService.createProject(this.projectForm.value).subscribe(
        (response: any) => {
          if ('id' in response || (response.id != 0 && response.id != null)) {
            this.utility.DisplayMessage(
              Enums.Message_Sucess,
              Enums.Message_Type_Sucess
            );
            this.router.navigate(['/project/listing']).then(() => {
              //window.location.reload();
            });
          } else {
            this.utility.DisplayMessage(
              Enums.Message_Failure,
              Enums.Message_Type_Error
            );
          }
          this.loading = false;
        },
        (error) => {
          this.utility.DisplayMessage(
            Enums.Message_Failure,
            Enums.Message_Type_Error
          );
        }
      );
    }
    this.submitted = true;
    
  }
  GetAllDatasets() {
    this.datasetService.getAllDataset(0, 100000000).subscribe(
      (response: any) => {
        if (response.count > 0) {
          this.arrAllDatasets = response.results;
        }
      },
      (error) => {
        this.utility.DisplayMessage(
          Enums.Message_Failure,
          Enums.Message_Type_Error
        );
      }
    );
  }
  SelectDataset(dataset_id: Number, event: Event) {
    $(event.target).parent().parent().toggleClass('active-row');
  }
}
