import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './_services';
import { LoadingOverlayService } from './_services/loadingOverlay.service';
import { User, Role } from './_models';


@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: User;
    fullName:String;
    loading: boolean;

    constructor(private loaderService: LoadingOverlayService, private authenticationService: AuthenticationService ) {
        this.authenticationService.user.subscribe(x => this.user = x);
        if(localStorage.getItem('user')!= null)
            this.fullName =  JSON.parse(localStorage.getItem('user')).user.name;

        this.loaderService.isLoading.subscribe((x) => {
            this.loading = x;
        });
    }

    get isAdmin() {
        return this.user.user.user_type === Role.Admin?true:false;
    }

    logout() {
        this.authenticationService.logout();
    }
}
