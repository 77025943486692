export enum Enums {
  Message_Type_Sucess = 'Success',
  Message_Type_Error = 'Error',
  Message_Type_ActiveTime = 'Active Time could not be saved. Contact Admin',
  Message_Type_AnnotationStats = 'There is something wrong in saving stats. Contact Admin',

  Form_Status_Valid = 'valid',
  Form_Status_Invalid = 'invalid',

  Message_Sucess = 'Operation Performed Sucessfully',
  Message_Failure = 'Oppss! Samething came up',
  Message_ActiveTime = 'Active Time could not be saved. Contact Admin',
  Message_AnnotationStats = 'There is something wrong in saving stats. Contact Admin',
  Message_No_Image_For_Work = 'All images processed',
  Message_Disable_Vertex_Dragging = 'Please disable vertex dragging',
  Message_No_Data_For_Export = 'No data available for export',
  Message_Polygon_Drawing = 'Please complete the drwaing first',

  Dataset_Images_Limit = 12,
  Records_Limit = 10,
  Page_Size = 10,
  Dataset_Limit = 15,
  Dataset_Offset = 0,

  Image_Type_All = 'all',
  Image_Type_Pending = 'pending',
  Image_Type_Labeled = 'labeled',
  Image_Type_Disapproved = 'disapproved',

  Role_Admin = 'Admin',
  Role_Labeler = 'Labeler',
  Role_Approver = 'Approver',

  Image_Status_Labeled = 'labeled',
  Image_Status_Approved = 'approved',
  Image_Status_Skipped = 'skipped',
  Image_Status_Rejected = 'rejected',
  Image_Status_Disapproved = 'disapproved',


  Project_Type_All = 'all',
  Project_Type_Pending = 'pending',
  Dataset_Single_Filesize = 5120,
  Dataset_Accumulative_Filesize = 256000,
  Failure_Accumulative_Filesize = 'Uploaded file size is greater than 250MB',
  Message_Dataset_Created = 'Data-set Created Successfully',
  Message_Dataset_Redirection = 'We are redirecting to listing..',
  Message_Upload_Error = 'Image is required!',
  Filesize_Failure = 'Uploaded file size is greater than 5MB',
  Filename_Failure = 'Project name is required',
  Attach_Dataset_Failure = 'Attach a dataset',
  Message_Polygons_draw='You can not submit before drawing any polygon. Please click SKIP or REJECT to proceed.',
  Message_Projectuser_Exist = 'This user already exist',
  Message_Labeler_Greater = 'Labeler or Approver Max count can not bhe greater than total images',
  Message_Labeler_Lesser = 'Labeler or Approver Max count can not bhe Lesser than Initial Max Count',

  Segment_Operation_BTF = 'Bring To Front',
  Segment_Operation_BTB = 'Bring To Back',
  Segment_Operation_Copy = 'Copy',
  Segment_Operation_Paste = 'Paste',
  Segment_Operation_Delete = 'Delete',

  Role_Admin_Id = 1,
  Role_Labeler_Id = 2,
  Role_Approver_Id = 3,
  // Message_Disable_Vertex_Dragging = "Message_Disable_Vertex_Dragging"
}
