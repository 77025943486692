import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingOverlayService } from '../_services/loadingOverlay.service';


@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayInterceptor implements HttpInterceptor  {

  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoadingOverlayService, private router: Router ) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * Do not proceed interceptor loading to "Dataset Creation" screen,
     * As it already have another loading of each image.
     */
     const currentRouteUrl = this.router.routerState.snapshot.url;
     if( currentRouteUrl.includes('dataset/new')  )
       return next.handle(req);
 
     //this.loaderService.isLoading;
     this.requests.push(req);
     this.loaderService.isLoading.next(true);
 
     return Observable.create(observer => {
       const subscription = next.handle(req)
         .subscribe(
           event => {
             if (event instanceof HttpResponse) {
               this.removeRequest(req);
               observer.next(event);
             }
           },
           err => {
             this.removeRequest(req);
             observer.error(err);
           },
           () => {
             this.removeRequest(req);
             observer.complete();
           });
       return () => {
         this.removeRequest(req);
         subscription.unsubscribe();
       };
    });
  }
}