<div class="header-title ken-burn white" data-parallax="scroll" data-position="top" data-natural-height="650"
    data-natural-width="1920" data-image-src="../../assets/images/bg-8.jpg">
    <div class="container" style="margin-top: 0px; opacity: 1;">
        <div class="title-base" style="margin-top: 109px;">
            <hr class="anima">
            <h1>Create New Dataset</h1>
            <p>Fill the dataset name and attach as many as images you want upto 256MB. </p>
        </div>
    </div>
</div>

<div class="section-empty section-item">
    <div class="section-bg-color">
        <div class="container content">
            <h4>New Dataset</h4>
            <form class="form-box form-ajax form-inline" [formGroup]="datasetForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-md-3">
                        <input id="name" name="name" placeholder="Name" type="text" formControlName="name"
                            class="form-control form-value"
                            [ngClass]="{ 'is-invalid': submitted && datasetForm.controls.name.errors }" />
                        <div *ngIf="submitted && datasetForm.controls.name.errors" class="invalid-feedback">
                            <div *ngIf="datasetForm.controls.name.errors.required">Dataset name is required</div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <textarea class="form-control form-value" formControlName="description"
                            placeholder="Description" rows="1"></textarea>
                    </div>
                    <div class="col-md-2 text-r">
                        <button class="btn-sm btn nav-justified" type="submit"  [disabled]="this.dataSaved==true" >Save</button>
                    </div>
                </div>
            </form>
            <hr class="space s">
        </div>
    </div>
</div>
<div class="container content">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="outer-container-upload-images">
                <div class="inner-container-upload-images">
                    <input type="file" multiple (change)="UploadDatasetImages($event)" />
                    <div class="container-upload-button">
                        <div>
                            <button class="btn-md btn" type="button">
                                <span>Choose files to upload</span>
                                <span></span>
                            </button>
                            <div>
                                <span>256MB maximum upload size</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 mt-xlg" id="containerImageUploading">
            <!-- <div class="col-md-12 mt-lg uploadSingleImageContainer">
                <div class="row vertical-row">
                    <div class="col-md-3">
                        <p class="progress-label">Image1.jpg</p>
                    </div>
                    <div class="col-md-9">
                        <div class="progress">
                            <div class="progress-bar" data-progress="90" data-trigger="null" style="width: 90%;">
                                <span><span class="counter" data-to="90" data-trigger="null">90</span> %</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-lg">
                <div class="row vertical-row">
                    <div class="col-md-3">
                        <p class="progress-label">Image2.jpg</p>
                    </div>
                    <div class="col-md-9">
                        <div class="progress">
                            <div class="progress-bar" data-progress="90" data-trigger="null" style="width: 100%;">
                                <span><span class="counter" data-to="100" data-trigger="null">100</span> %</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-lg">
                <div class="row vertical-row">
                    <div class="col-md-3">
                        <p class="progress-label">Image3.jpg</p>
                    </div>
                    <div class="col-md-9">
                        <div class="progress">
                            <div class="progress-bar" data-progress="90" data-trigger="null" style="width: 25%;">
                                <span><span class="counter" data-to="25" data-trigger="null">25</span> %</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="col-md-12">
            <div class="text-center mt20" *ngIf="datasetRedirection">
                <button class="btn-sm btn" type="submit" (click)="datasetListingRedirection()">Go to Dataset
                    Listing</button>
            </div>
        </div>

    </div>
</div>


<i class="scroll-top scroll-top-mobile fa fa-sort-asc"></i>