import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DatasetService {
  constructor(private http: HttpClient) {}

  createDataset(objData: any) {
    return this.http.post(`${environment.apiUrl}/dataset/`, objData);
  }
  createDatasetImage(formData: FormData): Observable<HttpEvent<any>> {
    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/datasetimage/`,
      formData,
      options
    );
    return this.http.request(req);
  }

  getAllDataset(offset: Number, limit: Number) {
    return this.http.get(
      `${environment.apiUrl}/dataset/?limit=${limit}&offset=${offset}`
    );
  }
  getDatasetImages(dataset_id: Number,offset: Number, limit: Number) {
    return this.http.get(
      `${environment.apiUrl}/datasetimage/?data_set=${dataset_id}&limit=${limit}&offset=${offset}`
    );
  }
  deleteDataset(dataset_id: Number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/dataset/${dataset_id}/`);
  }
}
