<div class="section-empty section-item">

    <div class="section-bg-color">
        <div class="container content">
            <h4>Filter Dataset</h4>
            <form class="form-box form-ajax form-inline" [formGroup]="datasetListingForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-md-10">
                        <input id="name" name="txtDatasetName" placeholder="Search Name" type="text"
                            class="form-control form-value" formControlName="name" [(ngModel)]="Search_Query" />
                    </div>
                    <!-- <div class="col-md-2 text-r">
                        <button class="btn-sm btn nav-justified" type="submit">Search</button>
                    </div> -->
                </div>
            </form>
            <hr class="space s">
        </div>
    </div>
</div>
<div class="container content">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
                <h4 class="text-center">{{currentDatasetName}}</h4>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <aside class="sidebar mi-menu">
                        <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                            [infiniteScrollContainer]="'.sidebar-nav'" [fromRoot]="false" (scrolled)="onScroll()"
                            [scrollWindow]="false">
                            <nav class="sidebar-nav">
                                <ul class="side-menu">
                                    <li *ngFor="let data of arrData" class="li-dataset">
                                        <a href="javascript:void(0);"
                                            (click)="GetDatasetImages(data.id,data.number_of_images,data.name)"><i
                                                class="im-big-data"></i> {{data.name}}</a>
                                        <i class="fa fa-times" (click)="DeleteDataset(data.id)"></i>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </aside>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <div id="lightboxModal" class="lightbox-hide">
                        <div class="lightbox-modal">
                            <div class="lightbox-container">
                                <span (click)="closeModal();">x</span>
                                <img src="{{lightboxImageFile}}" />
                                <div class="lightbox-values">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <strong>ID:</strong> {{lightboxId}}
                                        </div>
                                        <div class="col-md-6">
                                            <strong>Width:</strong> {{lightboxWidth}}
                                        </div>
                                        <div class="col-md-6">
                                            <strong>Height:</strong> {{lightboxHeight}}
                                        </div>
                                        <div class="col-md-6">
                                            <strong>Width:</strong> {{lightboxWidth}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-3" *ngFor="let data of arrDatasetImages">
                            <div class="dataset-images">
                                <img src="{{data.image_file}}" (click)="openModal(data.id);" />
                            </div>
                            <!-- <a class="img-box i-center" href="{{data.image_file}}" style="opacity: 1;">
                                <i class="fa fa-photo anima"></i>
                                <img alt="" src="{{data.image_file}}">
                            </a> -->
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div [ngClass]="showLoadMore ? 'list-nav' : 'list-nav hidden'">
                        <div class="text-center">
                            <a href="#" (click)="LoadMoreRecords()" class="btn btn-sm circle-button load-more-maso"
                                data-page-items="8" data-current-page="1" style="display: inline-block;">Load More <i
                                    class="fa fa-arrow-down"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<i class="scroll-top scroll-top-mobile fa fa-sort-asc"></i>