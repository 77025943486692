<div class="ugf-main-wrap ugf-bg">
    <div class="ugf-header">
        <div class="logo">
            <a href="#"><img src="../../assets/images/logo-full.svg" class="img-fluid" alt="Algoface Annotation Tool"></a>
        </div>
        <div class="alternet-access">
            <p>Don't have an account? <a routerLink="/register">Register now!</a></p>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col">
                <div class="ugf-container pt200">
                    <div class="content-wrap">
                        <div class="ugf-form-block-header">
                            <h1>Welcome Back!</h1>
                            <p>Enter your email address and password to <br> get access your account</p>
                        </div>
                        <div class="ugf-input-block">
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                        <div *ngIf="f.username.errors.required">Username is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                    </div>
                                </div>
                                <button [disabled]="loading" class="btn btn-primary">
                                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                  Login
                               </button>
                                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>