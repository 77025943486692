<div class="header-title ken-burn white" data-parallax="scroll" data-position="top" data-natural-height="650" data-natural-width="1920" data-image-src="../../assets/images/bg-8.jpg">
    <div class="container" style="margin-top: 0px; opacity: 1;">
        <div class="title-base" style="margin-top: 109px;">
            <hr class="anima">
            <h1>Create New Project</h1>
            <p>Fill the Project name and attach the Dataset(s) with it. </p>
        </div>
    </div>
</div>
<div class="section-empty section-item">

    <div class="section-bg-color">
        <div class="container content">
            <h4>New Project</h4>
            <form class="form-box form-ajax form-inline" [formGroup]="projectForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-md-3">
                        <input id="name" name="name" placeholder="Project Name" formControlName="name" type="text" class="form-control form-value" [ngClass]="{ 'is-invalid': submitted && projectForm.controls.name.errors }" />
                        <div *ngIf="submitted && projectForm.controls.name.errors" class="invalid-feedback">
                            <div *ngIf="projectForm.controls.name.errors.required">Project name is required</div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <textarea class="form-control form-value" placeholder="Project Description" formControlName="description" rows="1"></textarea>
                    </div>
                    <div class="col-md-2 text-r">
                        <button class="btn-sm btn nav-justified" type="submit">Save</button>
                    </div>
                </div>
            </form>
            <hr class="space s">
        </div>
    </div>
</div>
<div class="container content">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <table class="table table-condensed table-hover table-striped bootgrid-table" aria-busy="false">
                <thead class="custom-table-header">
                    <tr>
                        <th class="text-left">
                            <a href="javascript:void(0);" class="column-header-anchor sortable">
                                <span class="text">Name</span>
                                <span class="icon fa "></span>
                            </a>
                        </th>
                        <th class="text-left">
                            <a href="javascript:void(0);" class="column-header-anchor sortable">
                                <span class="text">No. of Images</span>
                                <span class="icon fa "></span>
                            </a>
                        </th>
                        <th class="text-left">
                            <a href="javascript:void(0);" class="column-header-anchor sortable">
                                <span class="text">Created On</span>
                                <span class="icon fa "></span>
                            </a>
                        </th>
                        <th class="text-left">
                            <a href="javascript:void(0);" class="column-header-anchor sortable">
                                <span class="text">Action</span>
                                <span class="icon fa "></span>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of arrAllDatasets | searchFilter: Search_Query | paginate: { itemsPerPage: Page_Size, currentPage: Page_Number }" id="{{data.id}}">
                        <td class="text-left">{{data.name}}</td>
                        <td class="text-left">{{data.number_of_images}}</td>
                        <td class="text-left">{{utility.DateDifferenceFromToday(data.created_at)}}</td>
                        <td class="text-left">
                            <a href="javascript:void(0);" class="btn btn-default btn-xs" (click)="SelectDataset(data.id,$event)">Attach</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- Pagination -->
    <div class="row">
        <div class="col-md-12 mt20">
            <pagination-controls (pageChange)="Page_Number = $event" class="col-center text-center"></pagination-controls>
        </div>
    </div>
</div>
<i class="scroll-top scroll-top-mobile fa fa-sort-asc"></i>
